<template>
  <div>
    <MainHeader/>
    <main>
      <MainBtn v-if="userRole === 'admin'" link="/unos-ugovor/novi/1">Unos ugovora</MainBtn>
      <MainBtn v-if="userRole === 'admin'" link="/unos-zns/novi/1">Unos ZNS-a</MainBtn>
      <MainBtn v-if="userRole === 'admin'" link="/unos-placanje/novi/1">Unos plaćanja</MainBtn>
      <MainBtn v-if="userRole === 'admin'" link="/unos-planiranje/novi/1">Unos planiranja</MainBtn>
      <MainBtn link="/pregled-ugovora" white>Pregled registra ugovora</MainBtn>
      <MainBtn link="/pregled-znsa" white>Pregled ZNS-a</MainBtn>
      <MainBtn link="/pregled-placanja" white>Pregled plaćanja</MainBtn>
      <MainBtn link="/pregled-planiranja" white>Pregled planiranja</MainBtn>
    </main>
    <div class="m-link m-link--blue" @click="logout">Odjavite se</div>
  </div>
</template>
<script>
import MainBtn from '../components/MainBtn.vue'
import MainHeader from '../components/MainHeader.vue'
import {useAuthStore} from "@/stores/auth";

export default {
  components: {
    MainBtn, MainHeader
  },

  computed: {
    userRole() {
      const authStore = useAuthStore();
      return authStore.userRole
    }
  },

  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.logout();
    }
  }
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.6rem;
  padding: 5rem 16rem;
  background: var(--white);
  @media (max-width: 768px) {
    gap: 1.6rem;
    img {
      max-width: 130px;
    }
    padding: 1.6rem;
  }
}

.m-link--blue{
  justify-content: center;
  margin: 0 auto 30px auto;
}

main {
  display: flex;
  flex-wrap: wrap;
  gap: 2.6rem;
  padding: 5rem;
  margin: auto;
  justify-content: center;
  max-width: 1300px;


  @media (max-width: 768px) {
    margin: 0;
    gap: 0;
    padding: 0;
  }
}

.m-btn {
  width: 100%;
  padding: 0;
  min-height: 51px;
  max-width: 260px;
  @media (max-width: 768px) {
    border-radius: 0;
    max-width: 100%;
    color: var(--white) !important;
    border-bottom: 1px solid var(--white);

    &:hover {
      background: var(--white) !important;
      color: var(--blue);
    }
    &:nth-of-type(odd) {
      background: var(--blue) !important;
    }
    &:nth-of-type(even) {
      background: var(--light-blue) !important;
    }
  }
}
</style>
