<template>
  <v-overlay :value="isLoadingComputed">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import {useLoaderStore} from "@/stores/loader";
import {mapState} from 'pinia'

export default {

  computed: {
    ...mapState(useLoaderStore, ['isLoading']),

    isLoadingComputed() {
      return this.isLoading;
    },
  },
}

</script>
<style scoped>
.v-overlay--active {
  z-index: 9999 !important;
}
</style>

