<template>
  <div id="app">
    <router-view/>
    <MainLoader/>

  </div>
</template>

<script>
import MainLoader from '@/components/MainLoader';

export default {
  components: {
    MainLoader
  }
}

</script>
<style lang="scss">

@import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';

#app {

  > div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  background-color: white!important;

}
</style>
