import {instance} from '@/main.js'


export default {
    post(endpoint, data) {
        return instance.post(endpoint, data)
    },
    get(endpoint) {
        return instance.get(endpoint);
    }
}