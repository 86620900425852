<template>
  <TableForm key="p3" :headersData="headersData" :formData="formData" :tableData="tableData"
             :setTableData="setTableData"/>
</template>

<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object

  },
  data: () => ({}),
  computed: {
    brojUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora;
    },
    brojPredujma() {
      return this.$route.params.id;
    },

    headersData() {


      return [
        {text: 'Broj ugovora', value: 'broj_ugovora', fixed: this.brojUgovora},
        {text: 'Broj predujma', value: 'broj_predujma', fixed: this.brojPredujma},

        {
          text: 'Iznos(EUR)',
          value: 'iznos',
          type: 'number',
          align: 'right',
          placeholder: 'Unesite iznos'
        },
        {
          text: 'Datum uplate',
          value: 'datum_uplate',
          placeholder: 'dd.mm.gggg',
          type: 'date'
        },
        {
          text: 'Status',
          value: 'status',
          type: 'select',
          options: [
            {value: 'Otvoreno', label: 'Otvoreno'},
            {value: 'Provedeno', label: 'Provedeno'},
          ]
        },
        {
          text: 'Komentar',
          value: 'Komentar',
          type: 'textarea',
          placeholder: 'Unesite Komentar'
        },

        {text: '', value: 'actions', sortable: false},
      ]
    },
  },

}

</script>


