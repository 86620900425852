import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'
import LoginView from '../views/LoginView.vue'
import InsertView from '../views/InsertView.vue'
import UgovorOne from '../components/unos-ugovor/FormOne'
import UgovorTwo from '../components/unos-ugovor/FormTwo'
import UgovorThree from '../components/unos-ugovor/FormThree'
import UgovorFour from '../components/unos-ugovor/FormFour'
import UgovorFive from '../components/unos-ugovor/FormFive'
import UgovorSix from '../components/unos-ugovor/FormSix'
import UgovorSeven from '../components/unos-ugovor/FormSeven'
import UgovorEight from '../components/unos-ugovor/FormEight'
import UgovorNine from '../components/unos-ugovor/FormNine'
import ZnsOne from '../components/unos-zns/FormOne'
import ZnsTwo from '../components/unos-zns/FormTwo'
import ZnsThree from '../components/unos-zns/FormThree'
import ZnsFour from '../components/unos-zns/FormFour'
import PlacanjeOne from '../components/unos-placanje/FormOne'
import PlacanjeTwo from '../components/unos-placanje/FormTwo'
import PlacanjeThree from '../components/unos-placanje/FormThree'
import PlaniranjeOne from '../components/unos-planiranje/FormOne'
import TableView from '../views/TableView'
import {pinia} from '@/main'
import {useAuthStore} from "@/stores/auth";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/unos-ugovor/:id', name: 'unos-ugovor',
        // name: 'home',
        component: InsertView,
        children: [
            {
                path: '1',
                name: '1',
                component: UgovorOne,
            },
            {
                path: '2',
                name: '2',
                component: UgovorTwo,
            },
            {
                path: '3',
                name: '3',
                component: UgovorThree,
            },
            {
                path: '4',
                name: '4',
                component: UgovorFour,
            },
            {
                path: '5',
                name: '5',
                component: UgovorFive,
            },
            {
                path: '6',
                name: '6',
                component: UgovorSix,
            },
            {
                path: '7',
                name: '7',
                component: UgovorSeven,
            },
            {
                path: '8',
                name: '8',
                component: UgovorEight,
            },
            {
                path: '9',
                name: '9',
                component: UgovorNine,
            }

        ],
    },
    {
        path: '/unos-zns/:id', name: 'unos-zns',
        component: InsertView,
        children: [
            {
                path: '1',
                name: '1',
                component: ZnsOne,
            },
            {
                path: '2',
                name: '2',
                component: ZnsTwo,
            },
            {
                path: '3',
                name: '3',
                component: ZnsThree,
            },
            {
                path: '4',
                name: '4',
                component: ZnsFour,
            },


        ],
    },
    {
        path: '/unos-placanje/:id', name: 'unos-placanje',
        // name: 'home',
        component: InsertView,
        children: [
            {
                path: '1',
                name: '1',
                component: PlacanjeOne,
            },
            {
                path: '2',
                name: '2',
                component: PlacanjeTwo,
            },
            {
                path: '3',
                name: '3',
                component: PlacanjeThree,
            },
        ],
    },
    {
        path: '/unos-planiranje/:id', name: 'unos-planiranje',
        // name: 'home',
        component: InsertView,
        children: [
            {
                path: '1',
                name: '1',
                component: PlaniranjeOne,
            },
        ],
    },
    {
        path: '/pregled-ugovora',
        name: 'pregled-ugovora',
        component: TableView
    },
    {
        path: '/pregled-znsa',
        name: 'pregled-znsa',
        component: TableView
    },
    {
        path: '/pregled-placanja',
        name: 'pregled-placanja',
        component: TableView
    },
    {
        path: '/pregled-planiranja',
        name: 'pregled-planiranja',
        component: TableView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            noAuthRequired: true,
        },
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            noAuthRequired: true,
        },
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    const authStore = useAuthStore(pinia);
    if (!authStore.isAuthenticated) {
        authStore.getAuthStatus().then(() => {
            if (authStore.isAuthenticated) {
                if (to.name === 'login') {
                    next({
                        name: 'home',
                    });
                }
                next();
            } else {
                if (!to.meta.noAuthRequired) {
                    next({
                        name: 'login',
                    });
                }
                next();
            }
        });
    } else {
        if (to.name === 'login') {
            next({
                name: 'home',
            });
        }
        next();
    }
});

export default router
