<template>
  <TableForm key="u6" :headersData="headersData" :formData="formData" :tableData="tableData"
             :setTableData="setTableData"/>
</template>


<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function
  },

  computed: {
    uneseniElementi() {

      let uneseniElementi = this.formData.tabs?.find(o => o.id == "5")?.form?.table;

      if (uneseniElementi) {
        let uneseniElementiValues = uneseniElementi.map(x => ({
          label: x.naziv_elementa,
          value: x.naziv_elementa,
          attributes: {id: x.id}
        }))
        console.log('uneseniElementiValues', uneseniElementiValues);
        return uneseniElementiValues;
      } else {
        return [];
      }

    },
    headersData() {
      return [

        {
          text: 'Naziv elementa*',
          value: 'naziv_elementa',
          type: 'select',
          options: this.uneseniElementi, validation: 'required'
        },
        {
          text: 'Opis troškova stavke*',
          value: 'opis_troska_stavke',
          validation: 'required',
          placeholder: 'Unesite opis troška stavke'
        },
        {text: 'Naziv stavke*', value: 'naziv_stavke', validation: 'required', placeholder: 'Unesite naziv stavke'},
        {
          text: 'Tekuća ili kapitalna sredstva*',
          value: 'tekuca_ili_kapitalna_sredstva',
          type: 'select', validation: 'required',
          options: [{value: 'Tekuća', label: 'Tekuća'}, {value: 'Kapitalna', label: 'Kapitalna'}]
        },
        {
          text: 'Oznaka stavke*',
          value: 'oznaka_stavke',
          validation: 'required',
          placeholder: 'Unesite oznaku stavke'
        },

        {
          text: 'Ukupan iznos stavke(EUR)*',
          value: 'ukupan_iznos_stavke',
          align: 'right',
          validation: 'required',
          type: 'number',
          placeholder: 'Unesite ukupan iznos stavke'
        },
        {
          text: 'Ukupan iznos bespovratnih sredstava(EUR)*',
          value: 'ukupan_iznos_bespovratnih_sredstava',
          align: 'right',
          validation: 'required',
          type: 'number',
          placeholder: 'Automatski izračun nakon unosa Ukupnog iznosa stavke',
          auto: '1'

        },
        {text: '', value: 'actions', sortable: false},
        {text: 'Eid', value: 'element_id', fixed: "0", sortable: false},
        {text: 'Id', value: 'id', fixed: "0", sortable: false},

      ]
    },
  },
}
</script>

