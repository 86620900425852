<template>

  <div class="form__inner up-one">
    <FormulateInput role="presentation" autocomplete="off"
                    name="broj_ugovora"
                    type="select"
                    label="Broj ugovora*"
                    placeholder="Odaberi"
                    validation="required"
                    :validation-messages="{required: 'Ovo polje je obavezno'}"
                    :options="brojeviUgovoraPlanova"
    />


    <FormulateInput role="presentation" autocomplete="off"
                    name="projekcija_n2"
                    type="text"
                    numberValidation
                    label="Projekcija (n+2)(EUR)"
                    placeholder="Unesite iznos"
    />


    <FormulateInput role="presentation" autocomplete="off"
                    name="tekuci_plann"
                    type="text"
                    numberValidation
                    label="Tekući plan(n)(EUR)"
                    placeholder="Unesite iznos"
    />
    <FormulateInput role="presentation" autocomplete="off"

                    name="status"
                    type="select"
                    label="Status"
                    placeholder="Odaberite"
                    :options="[
                { value: '', label: 'Odaberite' },
    { value: 'U provedbi', label: 'U provedbi' },
    { value: 'Raskinut', label: 'Raskinut' },
        { value: 'Završen', label: 'Završen' },

  ]"/>
    <FormulateInput role="presentation" autocomplete="off"
                    name="projekcija_n1"
                    type="text"
                    numberValidation
                    label="Projekcija (n+1)(EUR)"
                    placeholder="Unesite iznos"
    />


    <FormulateInput role="presentation" autocomplete="off"
                    name="napomena"
                    type="textarea"
                    label="Napomena"
                    placeholder="Unesite napomenu"
    />

  </div>


</template>
<script>
import formApi from "@/api/formApi";
import {mapActions} from "pinia";
import {useLoaderStore} from "@/stores/loader";

export default {
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object,
    formValues: Object
  },

  data: () => ({
    brojeviUgovoraPlanova: [],
    brojUgovoraPlaniranje: ''
  }),

  methods: {
    // ...mapState(useFormStore, ['brojeviUgovora']),
    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    async init() {
      try {
        this.startLoading();
        let res = await formApi.get('planiranja/ugovori');

        if (res.status === 200) {
          console.log('init res', res);
          let brojeviUgovora = res.data.map(ugovor => ({
            'value': ugovor.broj_ugovora,
            'label': ugovor.broj_ugovora
          }));
          console.log('brojeviUgovora', brojeviUgovora);

          this.brojeviUgovoraPlanova = brojeviUgovora;
          console.log('brojeviUgovoraPlanova', this.brojeviUgovoraPlanova);
          if (this.formValues.broj_ugovora) {
            this.brojeviUgovoraPlanova.push(this.formValues.broj_ugovora)
          }
          this.stopLoading();

        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }
    },
  },

  created() {
    this.init();
  }
}

</script>

<style lang="scss">
.up-one {
  height: fit-content;

  .formulate-input-wrapper {
    flex-grow: initial !important;
  }
}

</style>