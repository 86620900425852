var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__inner up-one"},[_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"broj_ugovora","type":"select","label":"Broj ugovora*","placeholder":"Odaberi","validation":"required","validation-messages":{required: 'Ovo polje je obavezno'},"options":_vm.brojeviUgovoraPlanova}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"projekcija_n2","type":"text","numberValidation":"","label":"Projekcija (n+2)(EUR)","placeholder":"Unesite iznos"}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"tekuci_plann","type":"text","numberValidation":"","label":"Tekući plan(n)(EUR)","placeholder":"Unesite iznos"}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"status","type":"select","label":"Status","placeholder":"Odaberite","options":[
              { value: '', label: 'Odaberite' },
  { value: 'U provedbi', label: 'U provedbi' },
  { value: 'Raskinut', label: 'Raskinut' },
      { value: 'Završen', label: 'Završen' },

]}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"projekcija_n1","type":"text","numberValidation":"","label":"Projekcija (n+1)(EUR)","placeholder":"Unesite iznos"}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"napomena","type":"textarea","label":"Napomena","placeholder":"Unesite napomenu"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }