import {defineStore} from 'pinia'

export const useFormStore = defineStore('form', {

    state: () => ({
        tabsStore: {
            'ugovor': {
                'title': 'Unos ugovora',
                "tabs": [
                    {
                        "id": '1',
                        "name": '1. Opći podaci o ugovoru',
                    },
                    {
                        "id": '2',
                        "name": '2. Podaci o korisniku ugovora',
                        "form": {
                            "oib": '123',
                            "puno_ime_korisnika": 'test',
                            "oib_korisnika": 'test',
                        }
                    },
                    {
                        "id": '3',
                        "name": '3. Unos županija ugovora',
                    },
                    {
                        "id": '4',
                        "name": '4. Podaci iz odluke o financiranju',

                    },
                    {
                        "id": '5',
                        "name": '5. Unos elemenata ugovora',

                    },
                    {
                        "id": '6',
                        "name": '6. Podaci o stavkama ugovora',
                    },
                    {
                        "id": '7',
                        "name": '7. Podaci o proračunu ugovora',
                    },
                    {
                        "id": '8',
                        "name": '8. Izmjene ugovora',
                    },
                    {
                        "id": '9',
                        "name": '9. Pregled korekcija',
                    },
                ]
            },
            'zns': {
                'title': 'Unos ZNS-a',
                "tabs": [
                    {
                        "id": '1',
                        "name": '1. Unos podataka ZNS-a',
                    },
                    {
                        "id": '2',
                        "name": '2. Unos stavaka ZNS-a',
                    },
                    {
                        "id": '3',
                        "name": '3. Unos plana dostave ZNS-a',
                    },
                    {
                        "id": '4',
                        "name": '4. Pregled korekcija',
                    },
                ]
            },
            'placanje': {
                'title': 'Unos plaćanja/predujma/prijeboja',
                "tabs": [
                    {
                        "id": '1',
                        "name": '1.Unos plaćanja/predujma/prijeboja',
                    },
                    {
                        "id": '2',
                        "name": '2.Pregled povrata',
                    },
                    {
                        "id": '3',
                        "name": '3.Povrat predujma',
                    },

                ]
            },
            'planiranje': {
                'title': 'Unos plana proračuna',
                "tabs": [
                    {
                        "id": '1',
                        "name": '1.Unos plana proračuna',
                    },

                ]
            },
        },
        brojeviUgovora: [],

    }),
    actions: {
        setBrojeviUgovora(data) {
           this.brojeviUgovora = data;
        },
    },
    getters: {
        // formData: (state) => state.formData,
    },
})
