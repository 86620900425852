<template>
  <TableForm key="u3" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData"/>
</template>

<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function
  },
  data: () => ({}),
  computed: {
    brojUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora;
    },

    headersData() {

      return [
        {text: 'Broj ugovora', value: 'broj_ugovora', fixed: this.brojUgovora},
        {
          text: 'Županija*',
          title: 'Županija',
          value: 'zupanija',
          type: 'select',
          validation: 'required',
          options: [
            {label: 'I ZAGREBAČKA', value: 'I ZAGREBAČKA'},
            {label: 'II KRAPINSKO - ZAGORSKA', value: 'II KRAPINSKO - ZAGORSKA'},
            {label: 'III SISAČKO - MOSLAVAČKA', value: 'III SISAČKO - MOSLAVAČKA'},
            {label: 'IV KARLOVAČKA', value: 'IV KARLOVAČKA'},
            {label: 'V VARAŽDINSKA', value: 'V VARAŽDINSKA'},
            {label: 'VI KOPRIVNIČKO - KRIZEVAČKA', value: 'VI KOPRIVNIČKO - KRIZEVAČKA'},
            {label: 'VII BJELOVARSKO - BILOGORSKA', value: 'VII BJELOVARSKO - BILOGORSKA'},
            {label: 'VIII PRIMORSKO - GORANSKA', value: 'VIII PRIMORSKO - GORANSKA'},
            {label: 'IX LIČKO - SENJSKA', value: 'IX LIČKO - SENJSKA'},
            {label: 'X VIROVITIČKO - PODRAVSKA', value: 'X VIROVITIČKO - PODRAVSKA'},
            {label: 'XI POŽEŠKO - SLAVONSKA', value: 'XI POŽEŠKO - SLAVONSKA'},
            {label: 'XII BRODSKO - POSAVSKA', value: 'XII BRODSKO - POSAVSKA'},
            {label: 'XIII ZADARSKA', value: 'XIII ZADARSKA'},
            {label: 'XIV OSIJEČKO - BARANJSKA', value: 'XIV OSIJEČKO - BARANJSKA'},
            {label: 'XV ŠIBENSKO - KNIŽEVAČKA', value: 'XV ŠIBENSKO - KNIŽEVAČKA'},
            {label: 'XVI VUKOVARSKO - SRIJEMSKA', value: 'XVI VUKOVARSKO - SRIJEMSKA'},
            {label: 'XVII SPLITSKO-DALMATINSKA', value: 'XVII SPLITSKO-DALMATINSKA'},
            {label: 'XVIII ISTARSKA', value: 'XVIII ISTARSKA'},
            {label: 'XIX DUBROVAČKO - NERETVANSKA', value: 'XIX DUBROVAČKO - NERETVANSKA'},
            {label: 'XX MEĐIMURSKA', value: 'XX MEĐIMURSKA'},
            {label: 'XXI GRAD ZAGREB', value: 'XXI GRAD ZAGREB'},
          ]

        },
        {text: '', value: 'actions', sortable: false},
      ]
    },
  },

}

</script>


