<template>
  <div class="table-view form__inner--table" data-app>
    <TableModal :showModal="showModal" :izvjestajData="izvjestajData" :setShowModal="setShowModal"/>
    <TableModalAll :showModal="showModal2"  :izvjestajDataAll="izvjestajDataAll" :setShowModal="setShowModal2"/>

    <header>
      <nav>
        <router-link to="/">
          <img alt="logo" src="@/assets/img/home-icon.png"/>
        </router-link>
        <p>{{ title }}</p>
      </nav>
    </header>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pretraži"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h2 delete-text">Jeste li sigurni da želite ukoniti ovaj ZNS?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="m-btn delete-btn" @click="closeDeleteModal">Natrag</div>
          <div class="m-btn delete-btn" @click="deleteZnsConfirm"> Da</div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <a v-if="userRole==='admin'" :href="addLink" class="m-btn m-btn--new">Dodaj u tablicu</a>

    <!-- eslint-disable -->
    <v-data-table
        :headers="headers"
        :items="tableData"
        :search="search"
    >
      <template v-slot:item.actions="{ item }">
        <div class="table__icons">
          <v-icon
              small
              class="mr-2"
              @click="goToContract(item)"
          >
            mdi-pencil
          </v-icon>
          <a v-if="$route.name === 'pregled-ugovora' || $route.name === 'pregled-znsa'" :href="ispisRow(item)"
             target="_blank">
            <v-icon
                small
            >
              mdi-download
            </v-icon>
          </a>
          <v-icon
              v-if="$route.name === 'pregled-znsa'"
              small
              @click="openDeleteModal(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon v-if="$route.name === 'pregled-ugovora'"
                  small
                  @click="getIzvjestaj(item)"
          >
            mdi-note-text
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <a :href="fileLink" target="_blank" class="m-btn" v-if="$route.name === 'pregled-ugovora' || $route.name === 'pregled-placanja'">Ispis</a>

    <a :href="pbiLink" target="_blank" class="m-btn pbi-btn" v-if="$route.name === 'pregled-ugovora'">Financijski izvještaj:<br>Registar ugovora</a>

    <!--    <div class="m-btn" @click="getFile">Ispis</div>-->
    <!--        <router-link :to="fileLink" tag="div" class="m-btn">Ispis</router-link>-->
    <div class="report-filters" v-if="$route.name === 'pregled-ugovora'">
      <p class="report-title">Izvještaj filteri:</p>

      <v-select
          :items="zupanije"
          v-model="zupanija"
          item-text="label"
          item-value="value"
          label="Županija"
          standard
      ></v-select>
      <v-select
          :items="statusi"
          v-model="status"
          item-text="label"
          item-value="value"
          label="Status"
          standard
      ></v-select>
      <v-select
          :items="godine"
          v-model="godinaPotpisa"
          item-text="label"
          item-value="value"
          label="Godina potpisa"
          standard
      ></v-select>
      <v-select
          :items="godine"
          v-model="godinaZavrsetka"
          item-text="label"
          item-value="value"
          label="Godina završetka"
          standard
      ></v-select>
      <a @click="getIzvjestajAll" target="_blank" class="m-btn m-btn--report" v-if="$route.name === 'pregled-ugovora'">Izvještaj</a>


    </div>



    <router-link to="/" tag="div" class="m-link">Povratak na početnu stranicu</router-link>
    <v-snackbar :color="snackbarColor"
                v-model="snackbar"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zatvori
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<!-- eslint-disable -->

<script>

import {mapActions, mapState} from 'pinia'
import {useFormStore} from "@/stores/form";
import {useLoaderStore} from "@/stores/loader";
import formApi from "@/api/formApi";
import TableModal from "@/components/TableModal";
import TableModalAll from "@/components/TableModalAll";
import {useAuthStore} from "@/stores/auth";

export default {
  components: {
    TableModal,
    TableModalAll
  },
  data() {
    return {
      search: '',
      tableData: [],
      snackbarColor: '',
      snackbar: false,
      snackbarText: '',
      showModal: false,
      showModal2: false,
      izvjestajData: {},
      izvjestajDataAll: [],

      dialogDelete: false,
      deletedZns: {},
      deletedZnsIndex: '',

      zupanija: '',
      godinaPotpisa: '',
      godinaZavrsetka: '',
      status: '',
      godine: [
        {label: 'Odaberi', value: ''},
        {label: '1990', value: '1990'},
        {label: '1991', value: '1991'},
        {label: '1992', value: '1992'},
        {label: '1993', value: '1993'},
        {label: '1994', value: '1994'},
        {label: '1995', value: '1995'},
        {label: '1996', value: '1996'},
        {label: '1997', value: '1997'},
        {label: '1998', value: '1998'},
        {label: '1999', value: '1999'},
        {label: '2000', value: '2000'},
        {label: '2001', value: '2001'},
        {label: '2002', value: '2002'},
        {label: '2003', value: '2003'},
        {label: '2004', value: '2004'},
        {label: '2005', value: '2005'},
        {label: '2006', value: '2006'},
        {label: '2007', value: '2007'},
        {label: '2008', value: '2008'},
        {label: '2009', value: '2009'},
        {label: '2010', value: '2010'},
        {label: '2011', value: '2011'},
        {label: '2012', value: '2012'},
        {label: '2013', value: '2013'},
        {label: '2014', value: '2014'},
        {label: '2015', value: '2015'},
        {label: '2016', value: '2016'},
        {label: '2017', value: '2017'},
        {label: '2018', value: '2018'},
        {label: '2019', value: '2019'},
        {label: '2020', value: '2020'},
        {label: '2021', value: '2021'},
        {label: '2022', value: '2022'},
        {label: '2023', value: '2023'},

      ],
      statusi: [
        {label: 'Odaberi', value: ''},
        {label: 'Raskinut', value: 'Raskinut'},
        {label: 'U provedbi', value: 'U provedbi'},
        {label: 'Završen', value: 'Završen'},
      ],
      zupanije: [
        {label: 'Odaberi', value: ''},
        {label: 'I ZAGREBAČKA', value: 'I ZAGREBAČKA'},
        {label: 'II KRAPINSKO - ZAGORSKA', value: 'II KRAPINSKO - ZAGORSKA'},
        {label: 'III SISAČKO - MOSLAVAČKA', value: 'III SISAČKO - MOSLAVAČKA'},
        {label: 'IV KARLOVAČKA', value: 'IV KARLOVAČKA'},
        {label: 'V VARAŽDINSKA', value: 'V VARAŽDINSKA'},
        {label: 'VI KOPRIVNIČKO - KRIZEVAČKA', value: 'VI KOPRIVNIČKO - KRIZEVAČKA'},
        {label: 'VII BJELOVARSKO - BILOGORSKA', value: 'VII BJELOVARSKO - BILOGORSKA'},
        {label: 'VIII PRIMORSKO - GORANSKA', value: 'VIII PRIMORSKO - GORANSKA'},
        {label: 'IX LIČKO - SENJSKA', value: 'IX LIČKO - SENJSKA'},
        {label: 'X VIROVITIČKO - PODRAVSKA', value: 'X VIROVITIČKO - PODRAVSKA'},
        {label: 'XI POŽEŠKO - SLAVONSKA', value: 'XI POŽEŠKO - SLAVONSKA'},
        {label: 'XII BRODSKO - POSAVSKA', value: 'XII BRODSKO - POSAVSKA'},
        {label: 'XIII ZADARSKA', value: 'XIII ZADARSKA'},
        {label: 'XIV OSIJEČKO - BARANJSKA', value: 'XIV OSIJEČKO - BARANJSKA'},
        {label: 'XV ŠIBENSKO - KNIŽEVAČKA', value: 'XV ŠIBENSKO - KNIŽEVAČKA'},
        {label: 'XVI VUKOVARSKO - SRIJEMSKA', value: 'XVI VUKOVARSKO - SRIJEMSKA'},
        {label: 'XVII SPLITSKO-DALMATINSKA', value: 'XVII SPLITSKO-DALMATINSKA'},
        {label: 'XVIII ISTARSKA', value: 'XVIII ISTARSKA'},
        {label: 'XIX DUBROVAČKO - NERETVANSKA', value: 'XIX DUBROVAČKO - NERETVANSKA'},
        {label: 'XX MEĐIMURSKA', value: 'XX MEĐIMURSKA'},
        {label: 'XXI GRAD ZAGREB', value: 'XXI GRAD ZAGREB'},
      ]
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(useFormStore, ['tabsStore', 'formStore', 'allStore', 'tableStore']),

    userRole() {
      const authStore = useAuthStore();
      return authStore.userRole
    },

    title() {
      if (this.$route.name === 'pregled-ugovora') {
        return 'Pregled ugovora'
      } else if (this.$route.name === 'pregled-znsa') {
        return 'Pregled ZNS-a'
      } else if (this.$route.name === 'pregled-placanja') {
        return 'Pregled registra plaćanja'
      } else if (this.$route.name === 'pregled-planiranja') {
        return 'Pregled planiranja'
      } else {
        return ''
      }
    },

    headersUgovor() {
      return [
        {
          text: 'Broj ugovora',
          align: 'start',
          value: 'broj_ugovora',
        },
        {text: 'Naziv ugovora', value: 'naziv_ugovora'},
        {text: 'Korisnik', value: 'korisnik'},
        {text: 'Ukupna vrijednost projekta', value: 'ukupna_vrijednost_projekta', align: 'right'},
        {text: '', value: 'actions', sortable: false},

      ]
    },
    headersZns() {
      return [
        {
          text: 'ZNS',
          align: 'start',
          value: 'broj_zns-a',
        },
        {text: 'Broj ugovora', value: 'broj_ugovora'},
        {text: 'Naziv ugovora', value: 'naziv_ugovora'},
        {text: 'Potraživani iznos(EUR)', value: 'potrazivani_iznos', align: 'right'},
        {text: 'Odobreni iznos(EUR)', value: 'odobreni_iznos', align: 'right'},
        {text: 'Izuzeti troškovi', value: 'izuzeti_troskovi', align: 'right'},
        {text: 'Neprihvatljivi troškovi', value: 'neprihvatljivi_troskovi', align: 'right'},
        {text: '', value: 'actions', sortable: false},

      ]
    },
    headersPlacanje() {
      return [
        {
          text: 'ZNS',
          align: 'start',
          value: 'broj_zns-a',
        },
        {text: 'Broj ugovora', value: 'broj_ugovora'},
        {text: 'Naziv ugovora', value: 'naziv_ugovora'},
        {text: 'Korisnik', value: 'korisnik'},
        {text: 'Iznos(EUR)', value: 'iznos', align: 'right'},
        {text: 'Status', value: 'status'},
        {text: 'Datum plaćanja', value: 'datum_placanja'},
        {text: 'Vrsta', value: 'vrsta'},
        {text: '', value: 'actions', sortable: false},

      ]
    },

    headersPlaniranje() {
      return [
        {text: 'Broj ugovora', value: 'broj_ugovora'},
        {text: 'Naziv ugovora', value: 'naziv_ugovora'},
        {text: 'Status', value: 'status'},
        {text: '', value: 'actions', sortable: false},


      ]
    },
    pbiLink() {
      return process.env.VUE_APP_API + "export/pbi/ugovori";
    },
    fileLink() {
      if (this.$route.name === 'pregled-ugovora') {
        return process.env.VUE_APP_API + `export/ugovori`
        // return "https://api/wp-json/ey-api/export/ugovori";
      } else if (this.$route.name === 'pregled-znsa') {
        return process.env.VUE_APP_API + "export/zns"
        // return "https://api/wp-json/ey-api/export/zns";
      } else if (this.$route.name === 'pregled-placanja') {
        return process.env.VUE_APP_API + "export/placanja"
        // return "https://api/wp-json/ey-api/export/placanja";
      } else if (this.$route.name === 'pregled-planiranja') {
        return process.env.VUE_APP_API + "export/planiranja"
        // return "https://api/wp-json/ey-api/export/planiranja";
      } else {
        return []
      }
    },

    addLink() {
      if (this.$route.name === 'pregled-ugovora') {
        return "/unos-ugovor/novi/1";
      } else if (this.$route.name === 'pregled-znsa') {
        return "/unos-zns/novi/1";
      } else if (this.$route.name === 'pregled-placanja') {
        return "/unos-placanje/novi/1";
      } else if (this.$route.name === 'pregled-planiranja') {
        return "/unos-planiranje/novi/1";
      } else {
        return []
      }
    },

    headers() {
      if (this.$route.name === 'pregled-ugovora') {
        return this.headersUgovor;
      } else if (this.$route.name === 'pregled-znsa') {
        return this.headersZns;
      } else if (this.$route.name === 'pregled-placanja') {
        return this.headersPlacanje;
      } else if (this.$route.name === 'pregled-planiranja') {
        return this.headersPlaniranje;
      } else {
        return []
      }
    },

  },
  methods: {
    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    async getIzvjestaj(value) {


      try {
        this.startLoading();
        let res = await formApi.get('report/ugovori?id=' + value.id);

        if (res.status === 200) {
          console.log('init res', res);
          this.izvjestajData = res.data;
          this.stopLoading();
        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }

      this.showModal = true;

      // if (this.$route.name === 'pregled-ugovora') {
      //   return process.env.VUE_APP_API + "export/ugovori/" + value.id
      // } else if (this.$route.name === 'pregled-znsa') {
      //   return process.env.VUE_APP_API + "export/zns/" + value.id
      // } else {
      //   return null
      // }
    },
    async getIzvjestajAll() {

      try {
        this.startLoading();

        let res = await formApi.get(`report/ugovori?zupanija=${encodeURI(this.zupanija)}&status=${encodeURI(this.status)}&godina_potpisa=${encodeURI(this.godinaPotpisa)}&godina_zavrsetka=${encodeURI(this.godinaZavrsetka)}`);

        if (res.status === 200) {
          console.log('init res', res);
          this.izvjestajDataAll = res.data;
          this.stopLoading();
        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }

      this.showModal2 = true;

      // if (this.$route.name === 'pregled-ugovora') {
      //   return process.env.VUE_APP_API + "export/ugovori/" + value.id
      // } else if (this.$route.name === 'pregled-znsa') {
      //   return process.env.VUE_APP_API + "export/zns/" + value.id
      // } else {
      //   return null
      // }
    },


    setShowModal(value) {
      this.showModal = value;
    },
    setShowModal2(value) {
      this.showModal2 = value;
    },

    activateSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    async getFile() {
      try {
        this.startLoading();
        let res;
        if (this.$route.name === 'pregled-ugovora') {
          res = await formApi.get('export/ugovori');
        } else if (this.$route.name === 'pregled-znsa') {
          res = await formApi.get('export/zns');
        } else if (this.$route.name === 'pregled-placanja') {
          res = await formApi.get('export/placanja');
        } else if (this.$route.name === 'pregled-planiranja') {
          res = await formApi.get('export/planiranja');
        }

        if (res.status === 200) {
          console.log('init res', res);
          this.stopLoading();
        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }
    },


    async init() {
      try {
        this.startLoading();
        let res;
        if (this.$route.name === 'pregled-ugovora') {
          res = await formApi.get('ugovori');
        } else if (this.$route.name === 'pregled-znsa') {
          res = await formApi.get('zns');
        } else if (this.$route.name === 'pregled-placanja') {
          res = await formApi.get('placanja');
        } else if (this.$route.name === 'pregled-planiranja') {
          res = await formApi.get('planiranja');
        }

        if (res.status === 200) {
          console.log('init res', res);
          this.stopLoading();
          this.tableData = res.data;
          console.log('this.$route.params.id', this.$route.params.id);

        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }

    },
    ispisRow(value) {
      // console.log(value);

      if (this.$route.name === 'pregled-ugovora') {
        return process.env.VUE_APP_API + "export/ugovori?id=" + value.id
      } else if (this.$route.name === 'pregled-znsa') {
        return process.env.VUE_APP_API + "export/zns?id=" + value.id
      } else {
        return null
      }
    },
    openDeleteModal(item) {
      this.deletedZnsIndex = this.tableData.indexOf(item)
      this.deletedZns = Object.assign({}, item)
      this.dialogDelete = true
    },
    closeDeleteModal(item) {
      this.deletedZnsIndex = ''
      this.deletedZns = {}
      this.dialogDelete = false
    },
    async deleteZnsConfirm() {
      let res;
      res = await formApi.post('delete/zns', {
        id: this.deletedZns.id
      });
      if (res.status === 200) {
        this.tableData.splice(this.deletedZnsIndex, 1)
        this.dialogDelete = false
      } else {
        alert('Došlo je do pogreške!');
      }
      
    },

    goToContract(value) {
      console.log(value);
      let type;
      if (this.$route.name === 'pregled-ugovora') {
        type = 'unos-ugovor/';
      } else if (this.$route.name === 'pregled-znsa') {
        type = 'unos-zns/';
      } else if (this.$route.name === 'pregled-placanja') {
        type = 'unos-placanje/';
      } else if (this.$route.name === 'pregled-planiranja') {
        type = 'unos-planiranje/';
      }
      let path = type + value.id + '/1';

      this.$router.push(path)
    }
  }


}
</script>

<style lang="scss">


.report-filters {
  border-top: 5px solid #053d7a;

  display: flex;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 20px 10px 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  .v-input{
    max-width: 200px;
  }

  .report-title {
    min-width: 100%;
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }

}

tr {
  .text-right {
    text-align: right!important;
  }
}

.table__icons {
  a {
    text-decoration: none !important;
    color: var(--blue);

    i {
      &:before {
        color: var(--blue);
      }
    }

  }

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
}


.v-dialog {
  border-radius: 0px !important;

  .v-card {
    border-radius: 0px !important;
  }
}

.table-view {
  tr {
    cursor: initial !important;
  }

  .text-start {
    a {
      text-decoration: none;
    }

    .v-icon {
      color: var(--blue);

      &:hover {
        color: var(--light-blue);

        &:before {
          color: var(--light-blue);
        }
      }

      margin: 0 5px;
      font-size: 22px !important;
    }
  }

  .v-data-table-header {
    th {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  .vrsta-select {
    flex-grow: initial;
    background: var(--blue);
    margin: 0;
    padding: 0 2.6rem;


    * {
      color: var(--white);
    }

  }

  .m-btn {

    margin: 20px auto 0px auto;

    &--new {
      margin: 0px auto 30px 30px;
    }
    &--report {
      margin: 0px 0px 0px 30px;
    }
  }

  .pbi-btn {
    font-size: 15px;
    padding: 1rem 3rem;
  }

  .m-link {
    color: black;
  }

  nav {
    position: relative;
    justify-content: center;

    a {
      position: absolute;
      left: 0;
      @media only screen and (max-width: 1023px) {
        left: 20px;
      }

    }
  }

  .v-card__title {
    background: var(--blue);
    padding-top: 0;
    padding-bottom: 4rem;
    margin-bottom: 3rem;

    * {
      color: var(--white) !important;
    }

  }

  .v-data-table {
    padding: 0 20px;

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

.table__icons {
  display: flex;
  align-items: center;
}

.delete-btn {
  margin-left: 30px !important;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.delete-text {
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: .0125em !important;
  padding: 40px 24px 10px !important;
  align-items: center !important;
  display: flex !important;
  flex-wrap: wrap !important;
  line-height: 2rem !important;
  word-break: break-all !important;
  background: inherit !important;
  margin-bottom: 0 !important;
}

</style>
