<template>
  <div class="unos">
    <header>
      <nav>
        <router-link to="/">
          <img alt="logo" src="@/assets/img/home-icon.png"/>
        </router-link>
        <p>{{ tabsData.title }}</p>
        <p>{{ tabsCompleted }}/{{ tabsCount }}</p>

      </nav>
      <v-tabs show-arrows v-model="tab" center-active
      >
        <v-tab v-for="tab in tabsData.tabs" :class="[isCompleted(tab), showPovratPredujma(tab)]" tag="div"
               :disabled="(tab.id !== '1' && isFinished) || completed"
               :to="tab.id"
               :key="tab.id">
          <span> {{ tab.name }}</span>

        </v-tab>
      </v-tabs>
    </header>
    <div class="completed" v-if="completed">
      <!--      <p>{{ tabsCompleted }}/{{ tabsCount }}</p>-->
      <p>Ispunili ste potrebne stavke, Vaš je unos spremljen.</p>
      <router-link :to="tableViewLink" tag="div" class="m-btn m-btn--white">Pregled unosa</router-link>
      <router-link to="/" tag="div" class="m-link">Povratak na početnu stranicu</router-link>
    </div>
    <main v-else class="unos__form">
      <a v-if="!$route.fullPath.includes('novi')" :href="tableViewLink" class="m-link m-link--blue">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>north_black_24dp</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="2-Desktop-Copy" transform="translate(-100.000000, -238.000000)">
              <g id="north_black_24dp"
                 transform="translate(112.000000, 250.000000) scale(1, -1) rotate(-90.000000) translate(-112.000000, -250.000000) translate(100.000000, 238.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <polygon id="Path" fill="#063D7A" fill-rule="nonzero"
                         points="5 9 6.41 10.41 11 5.83 11 22 13 22 13 5.83 17.59 10.42 19 9 12 2"></polygon>
              </g>
            </g>
          </g>
        </svg>
        <span> {{ tableViewText }}</span>
      </a>

      <FormulateForm
          name="myForm"
          v-model="formValues"
          @submit="submitHandler"
          v-on:keydown.enter.prevent
          @failed-validation="failedValidation"
          v-if="this.$route.params.id == 'novi' || (this.formData.tabs && this.allData)"
      >
        <router-view :formValues="formValues" @setFormValues="setFormValues" :tableData="this.tableData"
                     :activateSnackbar="activateSnackbar"
                     :setTableData="setTableData"
                     :setNumberInputs="setNumberInputs"
                     :setRoleInputs="setRoleInputs"
                     :allData="allData" :formData="formData"/>
        <InsertControls v-if="userRole === 'admin'" :setBtnType="setBtnType"/>
      </FormulateForm>
    </main>
    <v-snackbar :color="snackbarColor"
                v-model="snackbar"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zatvori
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<!-- eslint-disable -->

<script>

import {mapActions, mapState} from 'pinia'
import {useFormStore} from "@/stores/form";
import InsertControls from '@/components/InsertControls'
import formApi from '@/api/formApi';
import MainLoader from '@/components/MainLoader'
import {useLoaderStore} from '@/stores/loader'
import {useAuthStore} from "@/stores/auth";

export default {
  components: {InsertControls, MainLoader},
  data() {
    return {
      snackbarColor: 'red',
      formValues: {},
      tab: null,
      btnType: null,
      tableData: [],
      snackbar: false,
      completed: false,
      snackbarText: `Uspješno ste spremili korak`,
      allData: {},
      formData: {},
      isCreated: false,
    }
  },

  mounted() {
    this.init();
  },
  watch: {

    formData(newFormData, oldFormData) {
      this.$nextTick(function () {
        this.setNumberInputs()
        this.setRoleInputs()
      })
    },
    $route() {
      this.$nextTick(function () {
        this.setNumberInputs()
        this.setRoleInputs()
        let tab = this.formData?.tabs?.find(o => o.id == this.$route.name);
        this.init();
        // // console.log('obj form', obj);
        // if (tab) {
        //   console.log('tab.form', tab.form);
        //   this.setFormValues(tab.form);
        //   // if (tab.form.table) {
        //   //   this.formValues = tab.form.table;
        //   // } else {
        //   //   this.formValues = tab.form;
        //   // }
        // }
      })
    }
  },
  computed: {
    ...mapState(useFormStore, ['tabsStore']),

    userRole() {
      const authStore = useAuthStore();
      return authStore.userRole
    },

    tableViewLink() {
      if (this.$route.path.includes("/unos-ugovor")) {
        return '/pregled-ugovora';
      } else if (this.$route.path.includes("/unos-zns")) {
        return '/pregled-znsa';
      } else if (this.$route.path.includes("/unos-placanje")) {
        return '/pregled-placanja';
      } else if (this.$route.path.includes("/unos-planiranje")) {
        return '/pregled-planiranja';
      } else {
        return ""
      }
    },
    tableViewText() {
      if (this.$route.path.includes("/unos-ugovor")) {
        return 'Pregled registra ugovora';
      } else if (this.$route.path.includes("/unos-zns")) {
        return 'Pregled registra ZNS-a';
      } else if (this.$route.path.includes("/unos-placanje")) {
        return 'Pregled registra plaćanja';
      } else if (this.$route.path.includes("/unos-planiranje")) {
        return 'Pregled registra planiranja';
      } else {
        return ""
      }
    },

    isFinished() {
      return !this.formData?.tabs?.find(o => o.id);
    },
    tabsData() {
      if (this.$route.path.includes("/unos-ugovor")) {
        return this.tabsStore.ugovor;
      } else if (this.$route.path.includes("/unos-zns")) {
        return this.tabsStore.zns;
      } else if (this.$route.path.includes("/unos-placanje")) {
        return this.tabsStore.placanje;
      } else if (this.$route.path.includes("/unos-planiranje")) {
        return this.tabsStore.planiranje;
      } else {
        return ""
      }
    },
    tabsCount() {

      let vrsta = this.formData?.tabs?.find(o => o.id == '1').form.vrsta;

      if (!this.$route.path.includes("/unos-placanje")) {
        return this.tabsData?.tabs?.length;
      }
      if (vrsta === 'Predujam') {
        return 3;
      } else {
        return 2;
      }

    },
    tabsCompleted() {
      if (this.formData?.tabs) {
        return this.formData?.tabs?.filter(o => Object.keys(o.form).length !== 0).length;
      } else return 0;
    },
    finalStep() {
      return this.tabsData?.tabs?.length === Number(this.$route.name);
    },
  },

  methods: {

    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),
    ...mapActions(useFormStore, ['setBrojeviUgovora']),


    async init() {
      try {
        this.startLoading();
        let res;
        res = await formApi.get('ugovori');
        if (res.status === 200) {
          console.log('init resUgovori', res);

          this.stopLoading();
          this.allData.ugovori = res.data;
          let brojeviUgovora = this.allData?.ugovori?.map(ugovor => ({
            'value': ugovor.broj_ugovora,
            'label': ugovor.broj_ugovora
          }));
          this.setBrojeviUgovora(brojeviUgovora);
          this.setNumberInputs()
          this.setRoleInputs()
        }

      } catch (error) {
        this.stopLoading();
        this.activateSnackbar(error.response.data.message, 'red');
        console.log('error', error);
      }
      if (!this.$route.path.includes("/unos-ugovor")) {
        try {
          this.startLoading();
          let res;

          if (this.$route.path.includes("/unos-zns")) {
            res = await formApi.get('zns');
          } else if (this.$route.path.includes("/unos-placanje")) {
            res = await formApi.get('placanja');
          } else if (this.$route.path.includes("/unos-planiranje")) {
            res = await formApi.get('planiranja');
          }

          if (res.status === 200) {
            console.log('init res', res);
            this.stopLoading();
            if (this.$route.path.includes("/unos-zns")) {
              this.allData.zns = res.data;
            } else if (this.$route.path.includes("/unos-placanje")) {
              this.allData.placanja = res.data;
            } else if (this.$route.path.includes("/unos-planiranje")) {
              this.allData.planiranja = res.data;
            }
          }

        } catch (error) {
          this.stopLoading();
          this.activateSnackbar(error.response.data.message, 'red');
          console.log('error', error);
        }
      }

      if (this.$route.params.id !== 'novi') {
        try {
          this.startLoading();

          let res;

          if (this.$route.path.includes("/unos-ugovor")) {
            res = await formApi.get('ugovori?id=' + this.$route.params.id);
          } else if (this.$route.path.includes("/unos-zns")) {
            res = await formApi.get('zns?id=' + this.$route.params.id);
          } else if (this.$route.path.includes("/unos-placanje")) {
            res = await formApi.get('placanja?id=' + this.$route.params.id);
          } else if (this.$route.path.includes("/unos-planiranje")) {
            res = await formApi.get('planiranja?id=' + this.$route.params.id);
          }

          if (res.status === 200) {
            console.log('id res', res);
            this.stopLoading();
            this.formData = res.data;
            console.log('insertview form data', this.formData);
          }

          let obj = this.formData?.tabs?.find(o => o.id == this.$route.name);
          console.log('obj', obj);
          this.setFormValues(obj?.form);

        } catch (error) {
          this.stopLoading();
          console.log('error', error);
          this.activateSnackbar(error.response.data.message, 'red');
        }
      }
    },

    setNumberInputs() {
      console.log('setNumberInputs');
      let inputs = document.querySelectorAll("[numbervalidation]");
      let self = this;
      if (inputs) {
        inputs.forEach(input => {

          //get the name of the input
          var name = input.getAttribute("name");
          console.log('input', name);

          if (name === 'sufinanciranje') {
            //on keyup only allow numbers and comma
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
            input.addEventListener('keypress', function (evt) {
              const keyPressed = evt.key;
              if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
              }
            });

          } else {
            input.addEventListener("blur", function () {
              var inputVal = input.value;
              console.log(inputVal);

              if (inputVal === "") {
                return;
              }

              var originalLen = inputVal.length;
              var caretPos = input.selectionStart;

              if (inputVal.indexOf(",") >= 0) {
                var decimalPos = inputVal.indexOf(",");
                var leftSide = inputVal.substring(0, decimalPos);
                var rightSide = inputVal.substring(decimalPos);
                leftSide = leftSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                rightSide = rightSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                rightSide = rightSide.substring(0, 2);
                input.value = leftSide + "," + rightSide;
                caretPos = caretPos - (originalLen - input.value.length);
                input.setSelectionRange(caretPos, caretPos);
              } else {
                console.log('aaa', input.name);
                console.log('bbb', self.formValues);

                input.value = inputVal.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                caretPos = caretPos - (originalLen - input.value.length);
                input.setSelectionRange(caretPos, caretPos);
              }
              self.formValues[input.name] = input.value;
            });

            input.addEventListener("keyup", function () {
              var inputVal = input.value;
              if (inputVal === "") {
                return;
              }

              var originalLen = inputVal.length;
              var caretPos = input.selectionStart;

              // percentages fields, allow only numbers and "."
              if (input.placeholder && input.placeholder == 'Unesite postotak') {
                  console.log('percent');
                  inputVal = inputVal.replace(/[^0-9.]/g, "");
                  inputVal = inputVal;
              } else {
                  if (inputVal.indexOf(",") >= 0) {
                    var decimalPos = inputVal.indexOf(",");
                    var leftSide = inputVal.substring(0, decimalPos);
                    var rightSide = inputVal.substring(decimalPos);
                    leftSide = leftSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    rightSide = rightSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    rightSide = rightSide.substring(0, 2);
                    inputVal = leftSide + "," + rightSide;

                  } else {
                    inputVal = inputVal.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    inputVal = inputVal;
                  }
              }
              

              input.value = inputVal;

              var updatedLen = inputVal.length;
              caretPos = updatedLen - originalLen + caretPos;
              input.setSelectionRange(caretPos, caretPos);
            });
          }

        })
      }
    },


    setRoleInputs() {

      let inputs = document.querySelectorAll("input");
      let selects = document.querySelectorAll("select");
      if (this.userRole !== 'admin') {
        if (inputs) {
          inputs.forEach(input => {
            input.disabled = true;
          })
        }
        if (selects) {
          selects.forEach(select => {
            select.disabled = true;
          })
        }
      }

    },

    setFormValues(data) {
      this.formValues = {...data};
      if (this.formValues['broj_zns-a'] && this.formValues['zns_id']) {
          this.formValues['broj_zns-a'] = this.formValues['zns_id'];
      }
      console.log('this.formValues', this.formValues);
      this.$forceUpdate();
    },
    setTableData(data) {
      this.tableData = data;
    },
    isCompleted(tab) {
      let obj = this.formData?.tabs?.find(o => o.id == tab.id);
      if (obj?.form) {
        if (Object.keys(obj?.form)?.length !== 0) {
          return 'tab-completed'
        }
      }
    },

    showPovratPredujma(tab) {
      let vrsta = this.formData?.tabs?.find(o => o.id == '1').form.vrsta;
      // console.log('vrsta', vrsta);
      if (tab.name !== '3.Povrat predujma') {
        return ""
      } else {
        if (this.$route.params.id !== 'novi' && vrsta == 'Predujam') {
          return ""
        } else {
          return "hidden"
        }
      }


    },

    async submitHandler(data) {
      const submitData = {
        form: data,
        id: this.$route.params.id,
      }

      console.log(submitData);

      try {
        this.startLoading();
        let res;

        if (this.$route.path.includes("/unos-ugovor")) {
          res = await formApi.post('ugovori', submitData);
        } else if (this.$route.path.includes("/unos-zns")) {
          res = await formApi.post('zns', submitData);
        } else if (this.$route.path.includes("/unos-placanje")) {
          res = await formApi.post('placanja', submitData);
        } else if (this.$route.path.includes("/unos-planiranje")) {
          res = await formApi.post('planiranja', submitData);
        }

        if (res.status === 200) {
          this.stopLoading();
          let data = res.data;
          console.log('data', data);


          this.activateSnackbar('Podaci su uspješno spremljeni', 'green');

          if (this.$route.path !== '/unos-ugovor/:id' && this.finalStep) {
            this.completed = true;
          }
          if (this.$route.name === '1') {
            let path = this.$route.matched[0].path.substring(0, this.$route.matched[0].path.indexOf(':')) + data.post_id + '/1';
            if (path !== this.$route.fullPath) {
              await this.$router.push(path)
            }
          }

          if (this.btnType === "next") {
            if (Number(this.$route.name) < this.tabsCount) {
              this.goNext()
            }
          }
          if (this.btnType === "end") {
            this.completed = true;
          }


          if (this.$route.params.id !== 'novi') {
            try {
              this.startLoading();

              let res;

              if (this.$route.path.includes("/unos-ugovor")) {
                res = await formApi.get('ugovori?id=' + this.$route.params.id);
              } else if (this.$route.path.includes("/unos-zns")) {
                res = await formApi.get('zns?id=' + this.$route.params.id);
              } else if (this.$route.path.includes("/unos-placanje")) {
                res = await formApi.get('placanja?id=' + this.$route.params.id);
              } else if (this.$route.path.includes("/unos-planiranje")) {
                res = await formApi.get('planiranja?id=' + this.$route.params.id);
              }

              if (res.status === 200) {
                console.log('id res', res);
                this.stopLoading();
                this.formData = res.data;
                console.log('insertview form data', this.formData);
              }

              let obj = this.formData?.tabs?.find(o => o.id == this.$route.name);
              console.log('obj', obj);
              this.setFormValues(obj?.form);

            } catch (error) {
              this.stopLoading();
              console.log('error', error);
              this.activateSnackbar(error.response.data.message, 'red');
            }
          }

          this.$nextTick(function () {
            if (this.$route.matched[1].path === "/unos-ugovor/:id/2") {
              document.querySelector('.tab-1').click();
            }
          })

        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }

    },

    async failedValidation(data) {
      console.log('failed1', data);
      this.activateSnackbar('Sva potrebna polja nisu unesena', 'red');
    },

    async setBtnType(type) {
      this.btnType = type;
    },

    activateSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    goNext() {
      console.log('this.$route', this.$route);
      if (Number(this.$route.name) < this.tabsCount) {
        let path = this.$route.fullPath.replace(/\/[^\/]*$/, '/' + (Number(this.$route.name) + 1));
        this.$router.push(path);
      }
    }
  },
}
</script>

<style lang="scss">

.completed {
  background: var(--blue);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  .m-btn {
    justify-self: center;
    align-self: center;
  }

  p {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
}

.m-link {
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;

  &--blue {
    color: var(--blue);
    margin-right: auto;
    margin-left: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.v-snack__wrapper {
  &.red {
    background-color: #ff5252 !important;
  }

  &.green {
    background-color: #4caf50 !important;

  }

  * {
    color: var(--white) !important;
    font-size: 12px;
  }
}

.v-list-item__title {
  font-size: 14px !important;
}

.form__inner--table {
  .v-text-field .v-label--active {
    transform-origin: top left;
  }

  .v-input {
    margin: 0 10px;
  }

  .v-data-footer {
    font-size: 14px !important;

    * {
      font-size: 14px !important;
    }

  }

  tbody {
    tr:nth-of-type(odd) {
      background: rgba(42, 89, 141, 0.23) !important;
    }

    tr:nth-of-type(even) {
      background: white !important;
    }

    td {
      font-size: 16px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #342c2c;

      button {
        color: var(--blue) !important;
      }
    }

  }

  .v-data-table {
    width: 100%;

    header {
      margin-bottom: 40px;
    }
  }

  .v-data-table-header {
    background: var(--blue);

    i {
      color: var(--white) !important;
    }

    span {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);

    }
  }

  th {
    text-align: left !important;
  }

  .m-btn {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.form-dialog {
  padding: 3rem;
  display: flex;
  //min-height: 90vh;
  //align-items: flex-start;
  flex-wrap: wrap;
  background: var(--white);


  select {
    width: 100%;
    border-radius: 5px;
    min-height: 50px;
    padding: 15px;
  }

  * {
    //flex-grow: initial !important;
  }

  .v-select {
    .v-text-field__details {
      display: none;
    }

    .v-input__slot {
      border: 1px solid var(--blue);
      box-shadow: none !important;
      margin: 0 !important;
      min-height: 53px;
    }


    .v-label {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7d7a7a !important;
    }

    input {
      border: none !important;
    }
  }

}

.form__inner {


  .v-tabs-bar {
    border: 1px solid var(--blue);
  }

  .v-tabs-slider-wrapper {
    display: none;
  }

  .v-window {
    padding-bottom: 30px;
    width: 100%;
  }

  .v-tabs {
    max-height: 50px;
  }

  .formulate-input-dropdown {

    list-style: none;
    border: 1px solid var(--blue);
    border-radius: 3px;

    li {
      font-size: 16px;
      transition: all 0.3s;
      color: var(--blue) !important;
      background: var(--white) !important;
      border-bottom: 1px solid var(--blue);
      padding: 0.8rem 1.6rem;
    }

    [data-is-selected="true"] {
      color: var(--white) !important;
      background: var(--blue) !important;
    }

  }

  .v-tabs {
    margin-bottom: 3rem;
  }

  .v-tab {
    flex-grow: 1;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--blue) !important;
    background: var(--white) !important;
    padding: 0.8rem 5rem;
    min-height: 50px;
    max-width: none;
    position: relative;


    &:before {
      display: none;
    }

    .v-ripple__container {
      display: none;
    }

    &--active {
      color: var(--white) !important;
      background: var(--blue) !important;

    }

    &:nth-of-type(odd) {
      padding-left: 2.5rem;
    }

    &:nth-of-type(even) {
      &.v-tab--active {
        color: var(--white) !important;
        background: var(--blue) !important;

        &:after {
          position: absolute;
          content: '';
          display: block;
          right: 0;
          top: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 50px 25px;
          border-color: transparent transparent #ffffff transparent;
        }
      }
    }

    &:nth-of-type(even) {
      &:after {
        position: absolute;
        content: '';
        display: block;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 50px 25px;
        border-color: transparent transparent var(--blue) transparent;
      }
    }

  }

  .form-tabs {
    .v-slide-group__prev {
      display: none !important;
    }

    .v-slide-group__next {
      display: none !important;
    }

    @media only screen and (max-width: 768px) {

      .v-tab {
        padding: 0.6rem 1rem;

        &:nth-of-type(even) {
          padding-right: 3.5rem;
        }
      }
    }

    * {
      @media only screen and (max-width: 1023px) {
        font-size: 15px !important;
      }
      @media only screen and (max-width: 768px) {
        font-size: 10px !important;
      }
    }

  }


  &.custom-tabs {
    .v-tab {
      border-bottom: 1px solid var(--blue);

      &:after {
        display: none !important;
      }

      &:before {
        display: none !important;
      }
    }
  }


  .v-snack__wrapper {
    background-color: #ff5252 !important;

    * {
      color: var(--white) !important;
      font-size: 12px;
    }
  }

  th {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap;

    span {
      white-space: nowrap;
    }
  }


  .v-dialog {
    //min-width: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
    @media only screen and (max-width: 1023px) {

    }

    .v-card__title {
      font-size: 18px !important;
      padding-top: 40px !important;
    }

    .dialog-btns {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .m-btn {
        margin-bottom: 0;
      }
    }

  }
}

.formulate-input-errors {
  position: absolute;
  bottom: -12px;
}

.unos__form {
  overflow: hidden;
  //flex-wrap: wrap;
  flex-grow: 1;
  max-height: 100%;
  padding: 3.5rem 10rem;
  display: flex;
  gap: 3rem;
  width: 100%;
  //justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: 1023px) {
    padding: 1.5rem;
  }

  .form-dialog {
    .formulate-form {
      @media only screen and (max-width: 1023px) {
        gap: 0px !important;
      }
    }
  }

  .formulate-form {
    display: flex;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      gap: 30px;

    }


    .formulate-input-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      label {
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        //line-height: 5.45;
        letter-spacing: normal;
        color: var(--blue);
      }

    }

    .formulate-input-error {
      font-size: 16px;
    }

    .formulate-input input, textarea {
      border: solid 1px var(--blue);
      //background-color: #f8f8f8;
      min-height: 40px;
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7d7a7a;
    }

    .formulate-input select {
      border: solid 1px var(--blue);
      //background-color: #f8f8f8;
      min-height: 40px;
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7d7a7a;
    }

    .form__inner {
      padding-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      width: calc(100% - 192px);
      @media only screen and (max-width: 1023px) {
        width: 100%;
      }
      @media only screen and (max-width: 1023px) {
        flex-direction: column;
        flex-wrap: initial;
      }
    }

    .formulate-input-element {
      max-width: 100%;
      margin-top: auto;

      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 13px;
      }
    }
  }

  .formulate-input {
    //max-width: 350px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 50%;
    min-width: 50%;
    padding: 15px 30px;
    position: relative;
    margin: 0;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }

    &:before {
      content: '';
      height: 20px;
      width: 20px;
      background: var(--white);
      border: 2px solid var(--blue);
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 20px;
      z-index: 2;
    }

    &:after {
      content: '';
      width: 2px;
      background: var(--blue);
      position: absolute;
      left: 9px;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    &[data-has-value="true"] {
      &:before {
        background: var(--blue);
      }
    }

  }
}

.v-window-item {
  .formulate-input {
    max-width: 100%;
  }
}

header {
  background: var(--blue);
  padding: 4rem;
  @media only screen and (max-width: 1023px) {
    padding: 2rem 0;

    nav {
      a {
        img {
          max-width: 25px;
        }
      }
    }
  }

  .v-slide-group {
    .v-icon {
      color: var(--white);

      &:before {
        color: var(--white);
      }

      &:after {
        color: var(--white);
      }
    }
  }

  .v-slide-group__content {
    padding-top: 30px;
    position: relative;
    justify-content: center;

    &:before {
      content: '';
      top: 9px;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--white);
      position: absolute;
    }
  }

  .v-tabs-slider-wrapper {
    display: none;
  }

  .v-tabs-bar {
    background-color: var(--blue) !important;

    span {
      white-space: nowrap;
    }

    .v-tab {
      flex-grow: 1;
      padding-bottom: 5px;

      &--disabled {
        opacity: 1 !important;
      }

    }

    * {
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--white);
      @media only screen and (max-width: 1023px) {
        font-size: 13px;

      }
    }

    .v-tab {
      //white-space: nowrap;
      position: relative;

      &.hidden {
        display: none !important;
      }

      &:after {
        content: '';
        height: 20px;
        width: 20px;
        background: var(--blue);
        border: 2px solid var(--white);
        position: absolute;
        border-radius: 50%;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -30px;
        z-index: 2;
        opacity: 1;
      }

      &.tab-completed {
        &:after {
          background: var(--white);
        }
      }

      & > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .v-tab:not(.v-tab--active) {
      span {
        color: var(--white) !important;
        opacity: 0.49;
      }


    }
  }
}


nav {
  padding-bottom: 30px;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  @media only screen and (max-width: 1023px) {
    padding: 0 2rem 2rem 2rem;
    font-size: 14px;
  }
}

.unos {
  max-height: 100vh;
}
</style>
