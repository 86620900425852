import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import './assets/main.css'
import VueFormulate from '@braid/vue-formulate'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
import Autocomplete from './plugins/Autocomplete'
// import FormulateVueCurrencyPlugin from 'vue-formulate-currency'
import Currency from './plugins/Currency'


Vue.use(VueAxios, axios)
Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin)
export const pinia = createPinia()
Vue.config.productionTip = false;
Vue.use(VueFormulate,  {
  validationNameStrategy: ['label'],
  plugins: [Currency, Autocomplete  ]
})

axios.defaults.withCredentials = true;

export const instance = axios.create({
  // baseURL: 'https://040369cd-93cc-492f-aa53-894a2908f629.mock.pstmn.io/',
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  // headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
});

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
