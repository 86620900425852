<template>
  <router-link v-if="link" :to="link" tag="div" class="m-btn" :class="{ 'm-btn--white': white }">
    <span><slot></slot></span>
  </router-link>
  <button :type="type" @click="$emit('click')" v-else class="m-btn" :class="{ 'm-btn--white': white }">
    <span><slot></slot></span>
  </button>

</template>
<script>
export default {
  props: {
    white: Boolean,
    link: String,
    type: String
  }
}
</script>

<style lang="scss">
.m-btn {
  border-radius: 27px;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 1rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  justify-self: flex-start;
  background: var(--blue);
  color: var(--white) !important;
  transition: all 0.3s;
  cursor: pointer;
  border: 1px solid var(--blue) !important;
  text-decoration: none !important;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    //min-height: 50px;
    //transition: all 0.3s;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 1rem 3rem;

  }


  &--white {
    background: var(--white);
    color: var(--blue) !important;
    border: 1px solid var(--blue);

    //&:hover {
    //  background: var(--blue);
    //  color: var(--white);
    //  border: 1px solid var(--white);
    //}
  }

  &:hover {
    background: var(--light-blue) !important;
    color: var(--white) !important;
    border: 1px solid var(--white) !important;
  }

}

</style>
