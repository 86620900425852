export const dates = [
    '2023-01-01',
    '2023-01-06',
    '2023-04-09',
    '2023-04-10',
    '2023-05-01',
    '2023-05-30',
    '2023-06-08',
    '2023-06-22',
    '2023-08-05',
    '2023-08-15',
    '2023-11-01',
    '2023-11-18',
    '2023-12-25',
    '2023-12-26',
    '2024-01-01',
    '2024-01-06',
    '2024-03-31',
    '2024-04-01',
    '2024-05-01',
    '2024-05-30',
    '2024-06-22',
    '2024-08-05',
    '2024-08-15',
    '2024-11-01',
    '2024-11-18',
    '2024-12-25',
    '2024-12-26',
    '2025-01-01',
    '2025-01-06',
    '2025-04-20',
    '2025-04-21',
    '2025-05-01',
    '2025-05-30',
    '2025-06-19',
    '2025-06-22',
    '2025-08-05',
    '2025-08-15',
    '2025-11-01',
    '2025-11-18',
    '2025-12-25',
    '2025-12-26',
];
export const isWeekend = (date) => {
    return date.getDay() === 6 || date.getDay() === 0;
}
export const isHoliday = (date) => {
    return dates.includes(date)
}


