<template>
  <TableForm key="z2" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData" :allData="allData"/>
</template>

<script>
import TableForm from '@/components/TableForm';
import formApi from "@/api/formApi";
import {mapActions} from "pinia";
import {useLoaderStore} from "@/stores/loader";

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object,
    activateSnackbar: Function,
    formValues: Object,

  },
  data: () => ({
    currentUgovor: null,
  }),

  mounted() {
    this.init();
  },

  computed: {
    idUgovora() {
      let ugovor = this.allData?.ugovori?.find(ugovor => ugovor.broj_ugovora === this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora);
      return ugovor.id;
    },

    stavke() {

      let stavkeTable = this.currentUgovor?.tabs?.find(o => o.id == 6)?.form?.table;
      console.log('stavkeTable',stavkeTable);
      let stavke = stavkeTable?.map(x => ({
        "label": x.naziv_stavke,
        "value": x.naziv_stavke,
        attributes: {id: x.id}

      }))
      console.log('stavke',stavke);

      return stavke;
    },

    headersData() {


      return [
        {text: 'Naziv stavke', value: 'naziv_stavke', type: 'select', options: this.stavke},
        {
          text: 'Tekuća ili kapitalna sredstva',
          value: "tekuca_ili_kapitalna_sredstva",
          auto: '1',
        },
        {text: 'Naziv elementa', value: 'naziv_elementa', placeholder: 'Unesite naziv elementa', auto: '1'},

        {
          text: 'Potraživani iznos(EUR)*',
          value: 'potrazivani_iznos',
          align: 'right',
          type: 'number',
          validation: 'required',
          placeholder: 'Unesite iznos'
        },
        {
          text: 'Neprihvatljivi troškovi(EUR)*',
          value: 'neprihvatljivi_troskovi',
          align: 'right',
          type: 'number',
          validation: 'required',
          placeholder: 'Unesite iznos'
        },
        {
          text: 'Komentar',
          value: 'komentar',
          type: 'textarea',
          placeholder: 'Unesite komentar'
        },
        {
          text: 'Izuzeti troškovi(EUR)*',
          value: 'izuzeti_troskovi',
          align: 'right',
          type: 'number',
          validation: 'required',
          placeholder: 'Unesite iznos'
        },

        {
          text: 'Odobreni iznos(EUR)',
          value: 'odobreni_iznos',
          align: 'right',
          type: 'number',
          auto: '1',
          placeholder: 'Iznos se automatski izračunava/ 0,00(EUR)'
        },



        {text: '', value: 'actions', sortable: false},
        {text: 'Id', value: 'id', fixed: "0", sortable: false},
        {text: 'Eid', value: 'stavka_id', fixed: "0", sortable: false},

      ]
    },
  },

  methods: {
    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    async init() {
      console.log('5555555');
      console.log('this.idUgovora0',this.formData);

      console.log('this.idUgovora1',this.allData);

      try {
          this.startLoading();

          let res;
          console.log('this.idUgovora2',this.idUgovora);

          res = await formApi.get('ugovori?id=' + this.idUgovora);
          if (res.status === 200) {
            console.log('id res', res);
            this.stopLoading();
            this.currentUgovor = res.data;
            console.log('currentUgovor', this.currentUgovor);
          }

        } catch (error) {
          this.stopLoading();
          console.log('error', error);
          this.activateSnackbar(error.response.data.message, 'red');
        }
      }


  }

}

</script>


