<template>

  <div class="form__inner ">
    <div class="form__special">
      <FormulateInput role="presentation" autocomplete="off"
          name="vrsta"
          type="select"
          label="Vrsta*"
          @change="resetForm"
          placeholder="Odaberite"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          :options="[
                  { value: '', label: 'Odaberite' },
    { value: 'Plaćanje', label: 'Plaćanje' },
    { value: 'Predujam', label: 'Predujam' },
    { value: 'Prijeboj', label: 'Prijeboj' },
  ]"/>
    </div>

    <div class="form__container" key="Plaćanje" v-if="formValues.vrsta === 'Plaćanje'">
      <FormulateInput role="presentation" autocomplete="off"
          name="broj_ugovora"
          @change="resetUgovor"
          type="select"
          label="Broj ugovora*"
          placeholder="Odaberite"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          :options="this.brojeviUgovora"/>

      <FormulateInput role="presentation" autocomplete="off"
          name="datum_placanja_SAFU"
          type="date"
          label="Datum plaćanja(SAFU)"
          placeholder="dd.mm.gggg."
          :validation-messages="{after: 'Format nije ispravan'}"
          :validation="'optional|after:1900-01-01'"

      />


      <FormulateInput role="presentation" autocomplete="off"
          name="broj_zns-a"
          @change="setZns"
          v-model="brojZnsa"
          type="select"
          label="Broj ZNS-a*"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          :options="this.brojeviZnsa"
          :placeholder="this.brojeviZnsa.length > 0 ? 'Odaberite' : 'Nema podataka'"
      />

      <FormulateInput role="presentation" autocomplete="off"
          name="datum_placanja"
          type="date"
          label="Datum izvršenja plaćanja"
          placeholder="dd.mm.gggg."
          :validation-messages="{after: 'Format nije ispravan'}"
          :validation="'optional|after:1900-01-01'"

      />

      <FormulateInput role="presentation" autocomplete="off"
          name="iznos"
          type="text"
          numberValidation
          label="Iznos plaćanja(EUR)*"
          placeholder="Unesite iznos"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"/>


      <FormulateInput role="presentation" autocomplete="off"
          name="status"
          type="select"
          label="Status"
          placeholder="Odaberite"
          :options="[
                  { value: '', label: 'Odaberite' },
    { value: 'Otvoreno', label: 'Otvoreno' },
    { value: 'Provedeno', label: 'Provedeno' },
  ]"/>
      <FormulateInput role="presentation" autocomplete="off"
          name="rok_za_placanje"
          v-model="rok_za_placanje"
          type="date"
          label="Rok za plaćanje"
          placeholder="dd.mm.yyyy."
          disabled
      />


      <FormulateInput role="presentation" autocomplete="off"
          name="komentar"
          type="textarea"
          label="Komentar"
          placeholder="Unesite komentar"
      />
    </div>

    <!--  predujam-->
    <div class="form__container" key="Predujam" v-if="formValues.vrsta === 'Predujam'">
      <FormulateInput role="presentation" autocomplete="off"
          name="broj_ugovora"
          type="select"
          label="Broj ugovora*"
          placeholder="Odaberite"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          :options="this.brojeviUgovora"/>
      <FormulateInput role="presentation" autocomplete="off"
          numberValidation
          name="iznos"
          type="text"
          label="Iznos predujma(EUR)*"
          placeholder="Unesite iznos"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
      />
      <FormulateInput role="presentation" autocomplete="off"
          name="datum_placanja"
          type="date"
          label="Datum izvršenja plaćanja"
          placeholder="dd.mm.gggg."
          :validation-messages="{after: 'Format nije ispravan'}"
          :validation="'optional|after:1900-01-01'"

      />
      <FormulateInput role="presentation" autocomplete="off"
          name="komentar"
          type="textarea"
          label="Komentar"
          placeholder="Unesite komentar"
      />

      <FormulateInput role="presentation" autocomplete="off"
          name="datum_urudzbiranja_od_strane_safua"
          type="date"
          label="Datum urudžbiranja od strane SAFU-a*"
          placeholder="dd.mm.gggg."
          :validation-messages="{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'}"
          :validation="'bail|required|after:1900-01-01'"
      />
      <FormulateInput role="presentation" autocomplete="off"
          name="status"
          type="select"
          label="Status"
          placeholder="Odaberite"
          :options="[
                  { value: '', label: 'Odaberite' },
    { value: 'Otvoreno', label: 'Otvoreno' },
    { value: 'Provedeno', label: 'Provedeno' },
  ]"/>

      <FormulateInput role="presentation" autocomplete="off"
          name="rok_za_placanje"
          v-model="rok_za_placanje"
          type="date"
          label="Rok za plaćanje*"
          placeholder="dd.mm.gggg."
          :validation-messages="{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'}"
          :validation="'bail|required|after:1900-01-01'"
      />

    </div>
    <!--prijeboj-->
    <div class="form__container" key="Prijeboj" v-if="formValues.vrsta === 'Prijeboj'">
      <FormulateInput role="presentation" autocomplete="off"
          name="broj_ugovora"
          @change="resetUgovor"
          type="select"
          label="Broj ugovora*"
          placeholder="Odaberite"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          :options="this.brojeviUgovora"/>
      <FormulateInput role="presentation" autocomplete="off"
          name="iznos"
          type="text"
          numberValidation
          label="Iznos prijeboja(EUR)"
          placeholder="Unesite iznos"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"/>
      <FormulateInput role="presentation" autocomplete="off"
          name="broj_zns-a"
          @change="setZns"
          v-model="brojZnsa"
          :placeholder="this.brojeviZnsa.length > 0 ? 'Odaberite' : 'Nema podataka'"
          type="select"
          label="Broj ZNS-a*"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          :options="this.brojeviZnsa"/>


      <FormulateInput role="presentation" autocomplete="off"
          name="datum_prijeboja"
          type="date"
          label="Datum prijeboja*"
          placeholder="dd.mm.gggg."
          :validation-messages="{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'}"
          :validation="'bail|required|after:1900-01-01'"
      />
      <FormulateInput role="presentation" autocomplete="off"
          name="vrsta_znsa"
          type="text"
          disabled
          label="Vrsta ZNS-a*"
          placeholder="Odaberite ZNS"
          validation="required"
          :validation-messages="{required: 'Ovo polje je obavezno'}"
          v-model="znsVrsta"
        />
      <FormulateInput role="presentation" autocomplete="off"
          name="datum_urudzbiranja_od_strane_safua"
          type="date"
          label="Datum urudžbiranja od strane SAFU-a"
          placeholder="dd.mm.gggg."
          :validation-messages="{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'}"
          :validation="'bail|required|after:1900-01-01'"
      />
      <FormulateInput role="presentation" autocomplete="off"
          name="status"
          type="select"
          label="Status"
          placeholder="Odaberite"
          :options="[
                  { value: '', label: 'Odaberite' },
    { value: 'Otvoreno', label: 'Otvoreno' },
    { value: 'Provedeno', label: 'Provedeno' },
  ]"/>
      <FormulateInput role="presentation" autocomplete="off"
          name="komentar"
          type="textarea"
          label="Komentar"
          placeholder="Unesite komentar"
      />
    </div>

  </div>


</template>
<script>
import {isHoliday, isWeekend} from "@/config/dates";
import {mapActions, mapState} from "pinia";
import {useFormStore} from "@/stores/form";
import {useLoaderStore} from "@/stores/loader";
import formApi from "@/api/formApi";

export default {
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object,
    formValues: Object,
    setFormValues: Function,
    activateSnackbar: Function,
    setNumberInputs: Function,
    setRoleInputs: Function,
  },
  data: () => ({
    brojeviZnsa: [],
    currentZns: null,
    rokZaPlacanje: null,
    brojZnsa: null,
    znsVrsta: null,
    rok_za_placanje: null,
  }),

  computed: {
    ...mapState(useFormStore, ['brojeviUgovora']),

    vrstaZnsa() {
      return this.formValues.vrsta_znsa
    },

  },

  created() {
    this.unwatchFormValues = this.$watch('formValues', (newVal) => {

      if (newVal.broj_ugovora) {
        console.log('newVal', newVal);
        this.getUgovor(newVal.broj_ugovora)
        // Remove watcher
        this.unwatchFormValues()
      }

      if(newVal.zns_id) {
        this.brojZnsa = newVal.zns_id;
      }

    })

    this.znsVrsta = this.formData.tabs[0].form.vrsta_znsa;
    this.rok_za_placanje = this.formData.tabs[0].form.rok_za_placanje;
  },

  methods: {

    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    async getUgovor(brojUgovora) {


      try {
        this.startLoading();
        let res = await formApi.get('opcije?type=zns&ugovor=' + encodeURI(brojUgovora));

        if (res.status === 200) {
          console.log('ugovor data', res);
          this.stopLoading();
          let brojeviZnsa = res.data.map(zns => ({
            'value': zns["id"],
            'label': zns["zns_unos_podataka_broj_znsa"],
            'id': zns["id"],
          }));
          this.brojeviZnsa = brojeviZnsa;
        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }
    },

    async getZns(brojZnsa) {
      console.log('funciton', brojZnsa);
      // switched from broj zns-a to id because broj zns-a is no longer unique
      let zns = this.brojeviZnsa.find(zns => zns.id == brojZnsa);
      console.log('found zns', zns)
      try {
        this.startLoading();
        let res = await formApi.get('zns?id=' + zns.id);

        if (res.status === 200) {
          console.log('zns data', res);
          this.stopLoading();

          this.znsVrsta = res.data?.tabs[0]?.form['vrsta_zns-a'];
          this.brojZnsa = brojZnsa;

          let rokZaPlacanje = res.data?.tabs?.find(tab => tab.id == '1').form?.rok_za_odobrenje;
          if (rokZaPlacanje) {
            let date = new Date(rokZaPlacanje);
            date.setDate(date.getDate() + 30);

            while (isWeekend(date) || isHoliday(date)) {
              date.setDate(date.getDate() + 1);
            }

            let rokFormated = date.toISOString().split('T')[0];
            this.rok_za_placanje = rokFormated;
            // this.$emit('setFormValues', {...this.formValues, rok_za_placanje: rokFormated});
          }
        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }
    },


    resetForm(e) {
      this.$formulate.resetValidation('myForm');
      this.$nextTick(function () {
        console.log('reset form', e);
        this.$emit('setFormValues', {vrsta: e.target.value});
        console.log('this.formValues', this.formValues);
        this.setNumberInputs();
        this.setRoleInputs();
      });
    },

    resetUgovor(e) {
      this.$formulate.resetValidation('myForm');
      this.$nextTick(function () {
        console.log('reset ugovor');
        this.$emit('setFormValues', {vrsta: this.formValues.vrsta, broj_ugovora: e.target.value});
        console.log('this.formValues', this.formValues);
      });
      this.getUgovor(e.target.value);
    },
    setZns(e) {
      // console.log('SET ZNS', e.target);
      // console.log('SET ZNS value', e.target.value);
      console.log('SET ZNS value', e.target.value);
      this.getZns(e.target.value);
    },
  }
}

</script>

<style scoped>
.form__special {
  width: 100%;
  display: flex;
  max-height: 118px;
}

.form__container {
  display: flex;
  flex-wrap: wrap;
}
</style>