import MyFormulateAutocomplete from '../components/MyFormulateAutocomplete'

export default function (formulateInstance) {
    formulateInstance.extend({
        components: {
            MyFormulateAutocomplete
        },
        library: {
            autocomplete: {
                classification: 'text',
                component: 'MyFormulateAutocomplete'
            }
        }
    })
}