<template>
  <v-dialog
      :value="showModal"
      width="500"
      persistent
      :key="showModal"
  >
    <v-card>
      <v-card-title>
        <p>Izvještaji</p>

        <v-btn
            @click="setShowModal(false)"
            text
            icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <div class="modal__content">
        <div class="modal__item" :key="item.label" v-for="item in izvjestajData">
          <div class="modal__label">{{ item.label }}:</div>
          <div class="modal__value" v-if="Array.isArray(item.value)">
            <div class="modal__value--list">
              <span :key="text" v-for="(text,i) in item.value"><span v-if="i !== 0">,</span> {{ text }}</span>
            </div>
          </div>
          <p class="modal__value" v-else> <span v-if="item.value">{{ item.value }}</span>
          </p>

        </div>
      </div>


    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    setShowModal: Function,
    izvjestajData: Object,

  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">



.v-card__title {
  font-size: 18.4px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  justify-content: center;
  padding: 15px 20px !important;
  margin-bottom: 0 !important;
  position: relative;

  .v-btn {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

}

.modal__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #c8d3df;
  gap: 2px;
}

.modal__label {
  white-space: nowrap;
}

.modal__value--list {
  text-align: left;

}

.modal__item {
  display: flex;
  font-size: 16.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #053d7a;
  gap: 5px;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: space-between;

  //media
  @media (max-width: 768px) {
    //flex-direction: column;
    //align-items: flex-start;
  }
}

.modal__value {
  font-weight: bold;
  text-align: right;
}

</style>