<template>
  <div class="not-found">
    <p>Ova stranica ne postoji!</p>
    <router-link to="/" tag="div" class="m-link">Povratak na početnu stranicu</router-link>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss">

.not-found {
  height: 100vh;
  width: 100vw;
  background: var(--blue);
  color: var(--white);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
