<template>
  <TableForm key="u7" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData"/>
</template>


<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
  },

  computed: {

    uneseniElementi() {
      let uneseniElementi = this.formData.tabs?.find(o => o.id == "6")?.form?.table;
      console.log('uneseniElementi', uneseniElementi);
      if (uneseniElementi) {
        let uneseniElementiValues = uneseniElementi?.map(x => ({
          "label": x.naziv_stavke,
          "value": x.naziv_stavke,
          attributes: {id: x.id}

        }))
        console.log('uneseniElementiValues', uneseniElementiValues);

        return uneseniElementiValues;
      } else
        return []

    },

    esif() {
      return this.formData?.tabs?.find(o => o.id == "4")?.form?.esif_fond;
    },


    headersData() {

      if (this.esif === 'KF') {
        return [
          {
            text: 'Odabir stavke*',
            value: 'odabir_stavke',
            type: 'select',
            options: this.uneseniElementi, validation: 'required'
          },
          {text: 'KF%', value: 'kf', type: 'number', placeholder: 'Unesite postotak KF (%)', auto: '1'},
          {text: 'Državni proračun', value: 'drzavni_proracun', type: 'number', placeholder: 'Unesite postotak DP(%)', auto: '1'},

          {text: '563EFRR(K)', value: '563efrr_k', type: 'number', auto: '1', align: 'right',},
          {text: '563EFRR(T)', value: '563efrr_t', type: 'number', auto: '1', align: 'right',},
          {text: '562KF(K)', value: '562kf_k', type: 'number', auto: '1', align: 'right',},
          {text: '562KF(T)', value: '562kf_t', type: 'number', auto: '1',align: 'right',},
          {text: '12DP(K)', value: '12dp_k', type: 'number', auto: '1',align: 'right',},
          {text: '12DP(T)', value: '12dp_t', type: 'number', auto: '1',align: 'right',},
          {text: '', value: 'actions', sortable: false},
          {text: 'Sid', value: 'stavka_id', fixed: "0", sortable: false},
          {text: 'Id', value: 'id', fixed: "0", sortable: false},

        ]
      }
      if (this.esif === 'EFRR') {
        return [
          {
            text: 'Odabir stavke*',
            value: 'odabir_stavke',
            type: 'select',
            options: this.uneseniElementi, validation: 'required'
          },

          {text: 'EFRR%', value: 'efrr', type: 'number', placeholder: 'Unesite postotak EFRR (%)', auto: '1'},
          {text: 'Državni proračun', value: 'drzavni_proracun', type: 'number', placeholder: 'Unesite postotak DP(%)', auto: '1'},

          {text: '563EFRR(K)', value: '563efrr_k', type: 'number', auto: '1',align: 'right',},
          {text: '563EFRR(T)', value: '563efrr_t', type: 'number', auto: '1',align: 'right',},
          {text: '562KF(K)', value: '562kf_k', type: 'number', auto: '1',align: 'right',},
          {text: '562KF(T)', value: '562kf_t', type: 'number', auto: '1',align: 'right',},
          {text: '12DP(K)', value: '12dp_k', type: 'number', auto: '1',align: 'right',},
          {text: '12DP(T)', value: '12dp_t', type: 'number', auto: '1',align: 'right',},

          {text: '', value: 'actions', sortable: false},
          {text: 'Sid', value: 'stavka_id', fixed: "0", sortable: false},
          {text: 'Id', value: 'id', fixed: "0", sortable: false},

        ]
      } else return [
        {
          text: 'Odabir stavke*',
          value: 'odabir_stavke',
          type: 'select',
          options: this.uneseniElementi, validation: 'required'
        },
        {text: 'EFRR%', value: 'efrr', type: 'number', placeholder: 'Unesite postotak EFRR (%)', auto: '1'},
        {text: 'KF%', value: 'kf', type: 'number', placeholder: 'Unesite postotak KF (%)', auto: '1'},

        {text: 'Državni proračun', value: 'drzavni_proracun', type: 'number', placeholder: 'Unesite postotak DP(%)'},
        {text: '563EFRR(K)', value: '563efrr_k', type: 'number', auto: '1',align: 'right',},
        {text: '563EFRR(T)', value: '563efrr_t', type: 'number', auto: '1',align: 'right',},
        {text: '562KF(K)', value: '562kf_k', type: 'number', auto: '1',align: 'right',},
        {text: '562KF(T)', value: '562kf_t', type: 'number', auto: '1',align: 'right',},
        {text: '12DP(K)', value: '12dp_k', type: 'number', auto: '1',align: 'right',},
        {text: '12DP(T)', value: '12dp_t', type: 'number', auto: '1',align: 'right',},

        {text: '', value: 'actions', sortable: false},
        {text: 'Sid', value: 'stavka_id', fixed: "0", sortable: false},
        {text: 'Id', value: 'id', fixed: "0"},

      ]


    },
  },
}
</script>

