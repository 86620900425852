import MyFormulateCurrency from '../components/MyFormulateCurrency'

export default function (formulateInstance) {
    formulateInstance.extend({
        components: {
            MyFormulateCurrency,
        },
        library: {
            currency: {
                classification: 'text',
                component: 'MyFormulateCurrency',
            },
        },
        // rules: {
        //     min: ({ value }, minVal) => {
        //         return value >= minVal;
        //     },
        //     max: ({ value }, maxVal) => {
        //         return value <= maxVal;
        //     },
        // },
    });
}