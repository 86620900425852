<template>
  <div class="form__inner">
    <FormulateInput role="presentation" autocomplete="off"
        placeholder="Odaberite"
        type="select"
        name="prioritetna_os"
        label="Prioritetna os"
        validation="optional"
        :options="[
                { value: '', label: 'Odaberite' },
    { value: '7', label: '7' },
  ]"/>
    <FormulateInput role="presentation" autocomplete="off"
        placeholder="Odaberite"
        type="select"
        name="investicijski_prioritet"
        label="Investicijski prioritet"
        validation="optional"
        :options="[
                { value: '', label: 'Odaberite' },
    { value: '7a', label: '7a' },
    { value: '7b', label: '7b' },
      { value: '7i', label: '7i' },
    { value: '7ii', label: '7ii' },
      { value: '7iii', label: '7iii' },
  ]"/>
    <FormulateInput role="presentation" autocomplete="off"
        placeholder="Odaberite"
        type="select"
        name="specificni_cilj"
        label="Specifični cilj"
        validation="optional"
        :options="[
                { value: '', label: 'Odaberite' },
    { value: '7a1', label: '7a1' },
    { value: '7b1', label: '7b1' },
     { value: '7i1', label: '7i1' },
    { value: '7ii', label: '7ii' },
        { value: '7ii1', label: '7ii1' },
     { value: '7ii2', label: '7ii2' },
    { value: '7ii3', label: '7ii3' },
     { value: '7iii1', label: '7iii1' },

  ]"/>
    <FormulateInput role="presentation" autocomplete="off"
        placeholder="Odaberite"
        type="select"
        name="esif_fond"
        label="ESIF Fond*"
        validation="required"
        :validation-messages="{required: 'Ovo polje je obavezno'}"
        :options="[
                { value: '', label: 'Odaberite' },
    { value: 'KF', label: 'KF' },
    { value: 'EFRR', label: 'EFRR' },
  ]"/>
    <FormulateInput role="presentation" autocomplete="off"
        name="klasa"
        type="text"
        label="Klasa*"
        placeholder="XXX-XX/XX-XX/XXX"
        validation="required"

        :validation-messages="{required: 'Ovo polje je obavezno'}"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="urudzbeni_broj"
        type="text"
        label="Urudžbeni broj*"
        placeholder="XXX-XX-X-X-X-XX-XXX"
        validation="required"
        :validation-messages="{required: 'Ovo polje je obavezno'}"

    />
    <FormulateInput role="presentation" autocomplete="off"
        name="datum_odluke"
        type="date"
        label="Datum odluke"
        placeholder="DD.MM.GGGG."
        :max="pocetakUgovora"
        :validation-messages="{after: 'Format nije ispravan'}"
        :validation="'optional|after:1900-01-01'"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="sufinanciranje"
        type="text"
        numberValidation
        label="Unesite postotak sufinanciranja (%)"
        placeholder="Unesite postotak"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="unos_postotka_ovisno_o_odabranom_esif_fondu"
        type="text"
        numberValidation
        label="Unos postotka ovisno o odabranom ESIF fondu (%)"
        placeholder="Unesite postotak"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="sredstva_korisnika"
        type="text"
        numberValidation
        label="Sredstva korisnika (%)"
        placeholder="Unesite postotak"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="drzavni_proracun"
        type="text"
        numberValidation
        label="Državni proračun (%)"
        placeholder="Unesite postotak"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="iznos_ukupnih_bespovratnih_sredstava"
        type="text"
        numberValidation
        label="Iznos ukupnih bespovratnih sredstava(EUR)*"
        placeholder="Unesite iznos"
        validation="required"
        :validation-messages="{required: 'Ovo polje je obavezno'}"

    />
    <FormulateInput role="presentation" autocomplete="off"
        name="iznos_bespovratnih_sredstava_od_strane_eu"
        type="text"
        numberValidation
        label="Iznos bespovratnih sredstava od strane EU(EUR)"
        placeholder="Unesite iznos"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="iznos_bespovratnih_sredstava_iz_drzavnog_proracuna"
        type="text"
        numberValidation
        label="Iznos bespovratnih sredstava iz državnog proračuna(EUR)"
        placeholder="Unesite iznos"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="ukupan_iznos_sredstava_krajnjeg_korisnika"
        type="text"
        numberValidation
        label="Ukupan iznos sredstava krajnjeg korisnika(EUR)"
        placeholder="Unesite iznos"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="iznos_sredstava_krajnjeg_korisnika_iz_javnih_sredstava"
        type="text"
        numberValidation
        label="Iznos sredstava krajnjeg korisnika iz javnih sredstava(EUR)"
        placeholder="Unesite iznos"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="iznos_sredstava_krajnjeg_korisnika_iz_privatnih_sredstava"
        type="text"
        numberValidation
        label="Iznos sredstava krajnjeg korisnika iz privatnih sredstava(EUR)"
        placeholder="Unesite iznos"
        validation="optional"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="ukupan_iznos_prihvatljivih_izdataka"
        type="text"
        numberValidation
        label="Ukupan iznos prihvatljivih izdataka(EUR)"
        placeholder="Unesite iznos"
        validation="optional"
    />
  </div>
</template>


<script>

export default {
  props: {
    formData: Object,
  },

  computed: {
    pocetakUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.datum_potpisivanja_ugovora
    },
  },
}

</script>
