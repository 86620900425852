var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__inner"},[_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"broj_ugovora","type":"text","label":"Broj ugovora*","placeholder":"Upiši broj ugovora","validation":"required","validation-messages":{required: 'Ovo polje je obavezno'}}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"naziv_ugovora","type":"text","label":"Naziv ugovora*","placeholder":"Unesite naziv ugovora","validation":"required","validation-messages":{required: 'Ovo polje je obavezno'}}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","placeholder":"Odaberite","type":"select","name":"trenutni_status_projekta","label":"Trenutni status projekta","validation":"optional","options":[
              { value: '', label: 'Odaberite' },
  { value: 'Raskinut', label: 'Raskinut' },
  { value: 'U provedbi', label: 'U provedbi' },
  { value: 'Završen', label: 'Završen'  },
]}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"datum_pocetka_ugovora","type":"date","label":"Datum početka ugovora*","placeholder":"DD.MM.GGGG.","validation-messages":{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'},"validation":'bail|required|after:1900-01-01'}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"ukupna_vrijednost_projekta","type":"text","numberValidation":"","label":"Ukupna vrijednost projekta(EUR)","placeholder":"Unesite iznos","validation":"optional"}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"datum_kraja_ugovora","type":"date","label":"Datum kraja ugovora*","placeholder":"DD.MM.GGGG.","validation-messages":{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'},"validation":'bail|required|after:1900-01-01'}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","placeholder":"Odaberite","type":"select","name":"podrucje_ugovora","label":"Područje ugovora","validation":"optional","options":[
  { value: '', label: 'Odaberite' },
  { value: 'Kontinentalna Hrvatska', label: 'Kontinentalna Hrvatska' },
  { value: 'Jadranska Hrvatska', label: 'Jadranska Hrvatska' },
]}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","name":"datum_potpisivanja_ugovora","type":"date","label":"Datum potpisivanja ugovora*","placeholder":"DD.MM.GGGG.","validation-messages":{required: 'Ovo polje je obavezno',bail: 'Ovo polje je obavezno',after: 'Ovo polje je obavezno'},"validation":'bail|required|after:1900-01-01'}}),_c('FormulateInput',{attrs:{"role":"presentation","autocomplete":"off","placeholder":"Odaberite","type":"select","name":"funkcijsko_podrucje","label":"Funkcijsko područje","validation":"optional","options":[
              { value: '', label: 'Odaberite' },
  { value: '0451 – Cestovni promet', label: '0451 – Cestovni promet' },
  { value: '0452 – Promet vodnim putevima', label: '0452 – Promet vodnim putevima' },
  { value: '0453 – Željeznički promet', label: '0453 – Željeznički promet' },
  { value: '0454 – Zračni promet', label: '0454 – Zračni promet'  },
]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }