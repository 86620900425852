<template>

  <div class="form__inner">
    <v-tabs class="form-tabs" v-model="tab">
      <v-tab class="tab-1"
      >
        <span> Odabir postojećeg korisnika</span>
      </v-tab>
      <v-tab
      >
        <span> Unos novog korisnika</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
      >

          <FormulateInput role="presentation" autocomplete="off"
              v-if="tab===0"
              name="oib"
              type="autocomplete"
              label="OIB*"
              placeholder="Odaberite"
              validation="required"
              :value="formData.tabs[1].form.oib"
              :validation-messages="{required: 'Ovo polje je obavezno'}"
              :options="oibs"/>

      </v-tab-item>
      <v-tab-item
      >

        <FormulateInput role="presentation" autocomplete="off"
            v-if="tab===1"
            name="puno_ime_korisnika"
            type="text"
            label="Puno ime korisnika*"
            placeholder="Unesite puno ime korisnika"
            validation="required"
            :validation-messages="{required: 'Ovo polje je obavezno'}"
        />
        <FormulateInput role="presentation" autocomplete="off"
            v-if="tab===1"
            name="oib_korisnika"
            type="text"
            label="OIB Korisnika*"
            placeholder="Unesite OIB korisnika"
            :validation="[
              ['required'],
              ['max', 11, 'length'],
              ['min', 11, 'length']
            ]"
            :validation-messages="{required: '', max: 'OIB mora imati 11 znakova', min: 'OIB mora imati 11 znakova'}"
        />
      </v-tab-item>
    </v-tabs-items>


  </div>
</template>
<script>


import formApi from "@/api/formApi";
import {mapActions} from "pinia";
import {useLoaderStore} from "@/stores/loader";

export default {
  props: {
    allData: Object,
    formData: Object,
  },

  data() {
    return {
      tab: null,
      oibs: [],

    }
  },
  mounted() {
    this.getOibs()
  },

  methods: {
    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    async getOibs() {
      try {
        this.startLoading();

        let res;

        res = await formApi.get('opcije?type=korisnici');

        if (res.status === 200) {
          console.log('id res', res);
          this.stopLoading();
          let oibsData = res.data.map(x => ({
            "label": x.oib_korisnika,
            "value": x.oib_korisnika,
          }))
          this.oibs = oibsData;
          console.log('oibs', oibsData);
        }

      } catch (error) {
        this.stopLoading();
        console.log('error', error);
        this.activateSnackbar(error.response.data.message, 'red');
      }
    }

  }
}

</script>


<style lang="scss">

</style>
