import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import hr from 'vuetify/lib/locale/hr'

Vue.use(Vuetify);

// Vue.component('app', {
//     methods: {
//         changeLocale () {
//             this.$vuetify.lang.current = 'hr'
//         },
//     },
// })

export default new Vuetify({
    lang: {
        locales: { hr },
        current: 'hr',
    },
});
