<template>
  <div class="login">
    <MainHeader/>
    <main>
      <FormulateForm
          @submit="submitHandler"
      >
        <FormulateInput
            placeholder="Email"
            name="user_login"
            :validation-messages="{required: 'Ovo polje je obavezno'}"
            validation="required"
            type="email"
            v-model="email"
        />
        <FormulateInput
            placeholder="Lozinka"
            name="user_password"
            :validation-messages="{required: 'Ovo polje je obavezno'}"
            validation="required"
            type="password"
            v-model="password"

        />
<!--        <div class="m-link">Zaboravili ste lozinku?</div>-->
        <button class="m-btn m-btn--white">Prijavi se</button>
      </FormulateForm>
    </main>
    <v-snackbar :color="snackbarColor"
                v-model="snackbar"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zatvori
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import MainHeader from '../components/MainHeader.vue'
import formApi from "@/api/formApi";
import {mapActions} from "pinia";
import {useLoaderStore} from "@/stores/loader";

export default {
  components: {
    MainHeader
  },
  data() {
    return {
      password: '',
      message: '',
      email: '',
      snackbarColor: '',
      snackbar: false,
      snackbarText: '',
    };
  },
  methods: {
    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    activateSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    async submitHandler(data) {

      try {
        this.startLoading();
        let res = await formApi.post('login', data );
        if (res.status === 200) {
          this.stopLoading();
          this.activateSnackbar('Uspješno ste prijavljeni', 'green');
          console.log(res.data);
          // await this.$router.push({name: 'Home'});
          window.location.reload(false);

        }

      } catch (error) {
        this.stopLoading();
        this.activateSnackbar(error.response.data.message, 'red');
        console.log('error', error);
      }

    }
  }
}
</script>

<style lang="scss">
.login {
  background: var(--blue);
  min-height: 100vh;

  .formulate-input-error {
    color: var(--white);
    font-size: 10px;
  }

  input {
    background: var(--white) !important;
    box-shadow: none;
    border: none;
    min-height: 50px;
    font-size: 19px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7d7a7a;
  }

  .formulate-input-errors {
    bottom: -18px;
  }

  .formulate-input {
    margin-bottom: 20px;
  }

  .formulate-input-element {
    min-width: 300px;
  }

  .m-btn {
    margin: 3.6rem auto 0 auto;
  }

  main {
    display: flex;
    flex-wrap: wrap;
    gap: 2.6rem;
    padding: 5rem;
    flex-grow: 1;
    margin: auto 0;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      margin: 0;
      gap: 0;
      padding: 0;
    }
  }
}


</style>
