<template>
  <TableForm key="u5" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData"/>
</template>

<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function
  },

  computed: {
    brojUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora;
    },

    ugovorId() {
      return this.formData.id;
    },

    headersData() {
      return [

        {text: 'Ugovor_ID', value: 'ugovor_id', fixed: this.ugovorId},
        {text: 'Broj ugovora', value: 'broj_ugovora', fixed: this.brojUgovora},
        {
          text: 'Naziv elementa*',
          value: 'naziv_elementa',
          validation: 'required',
          placeholder: 'Unesite naziv elementa'
        },
        {
          text: 'Oznaka elementa',
          value: 'oznaka_elementa',
          placeholder: 'Unesite oznaku elementa'
        },

        {text: '', value: 'actions', sortable: false},
        {text: 'Id', value: 'id', fixed: "0", sortable: false},

      ]
    },
  },
}

</script>
