<template>
  <div class="form__inner form__inner--table" :class="hideIdColumn" data-app>
    <FormulateInput role="presentation" autocomplete="off"
        class="hide-input"
        name="table"
        type="text"
        validation="required"
        :value="tableItems">
    </FormulateInput>
    <!-- eslint-disable -->

    <v-data-table
        :search="search"
        :headers="headers"
        :items="tableItems"
        class="elevation-1"
        :class="expanded && 'table-expanded'"
        no-data-text="Nema podataka"
    >

      <template v-slot:top>

        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="table-btns">

                <div v-bind="attrs" v-if="userRole === 'admin'"
                     v-on="on" class="m-btn">Dodaj u tablicu
                </div>
                <v-btn
                    text
                    icon
                    class="table-expand" @click="expanded = !expanded"
                >
                  <v-icon>
                    mdi-arrow-expand
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <span class="text-h5">{{ formTitle }}</span>
            <div class="form-dialog">
              <div class="back-icon" @click="close">
                <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Age-gate-+-cookie-consent---otkrijiqos-register-app---light-mode"
                       transform="translate(-1147.000000, -190.000000)" fill="#34303D" stroke="#34303D"
                       stroke-width="0.983606557">
                      <g id="Group" transform="translate(1148.206373, 191.157193)">
                        <rect id="Rectangle"
                              transform="translate(5.564119, 5.564119) rotate(45.000000) translate(-5.564119, -5.564119) "
                              x="4.58051238" y="-1.32112697" width="1.96721311" height="13.7704918"
                              rx="0.983606557"></rect>
                        <rect id="Rectangle-Copy"
                              transform="translate(5.564119, 5.564119) rotate(-225.000000) translate(-5.564119, -5.564119) "
                              x="4.58051238" y="-1.32112697" width="1.96721311" height="13.7704918"
                              rx="0.983606557"></rect>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>


              <FormulateForm
                  @submit="save"
                  v-model="editedItem"
                  @input="autoValue"
                  v-on:keydown.enter.prevent
                  name="myForm2">


                <template v-for="(item) in headers">
                  <FormulateInput role="presentation" autocomplete="off" v-if="item.value!=='actions' && item.type==='textarea'" type="textarea"
                                  :key="item.value" :label="item.text" :name="item.value"
                                  :class="inputClass(item)"
                                  :value="inputValue(item)"
                                  :placeholder="item.placeholder"
                                  :validation="item.validation"
                                  :validation-messages="{required: 'Ovo polje je obavezno'}"

                  />
                  <FormulateInput role="presentation" autocomplete="off" v-if="item.value!=='actions' && (!item.type || item.type==='number')" type="text"
                                  :key="item.value" :label="item.text" :name="item.value"
                                  :class="inputClass(item)"
                                  :value="inputValue(item)"
                                  @keypress="setNumberInputs(item.type)"
                                  :numberValidation="item.type === 'number' && '1'"
                                  @keydown="checkInput"
                                  :placeholder="item.placeholder"
                                  :validation="item.validation"
                                  :validation-messages="{required: 'Ovo polje je obavezno'}"

                  />
                  <FormulateInput role="presentation" autocomplete="off"
                      v-if="item.value!=='actions' && item.type==='select'"
                      :name="item.value"
                      :key="item.value"
                      @change="setId"
                      type="select"
                      :placeholder="item.options?.length > 0 ? 'Odaberite' : 'Nema podataka'"
                      :label="item.text"
                      :options="item.options"
                      :validation="item.validation"
                      :validation-messages="{required: 'Ovo polje je obavezno'}"

                  />
                  <FormulateInput
                    role="presentation"
                    autocomplete="off"
                    v-if="item.value !== 'actions' && item.type === 'date'"
                    :name="item.value"
                    :key="item.value"
                    type="date"
                    :label="item.text"
                    :validation="item.validation ? item.validation + '|after:1900-01-01' : 'after:1900-01-01'"
                    :validation-messages="{ required: 'Ovo polje je obavezno', bail: 'Ovo polje je obavezno', after: 'Ovo polje je obavezno' }"
                  />
                </template>
                <div class="dialog-btns">
                  <button class="m-btn" type="submit"> Spremi u tablicu</button>
                </div>
              </FormulateForm>

            </div>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h2">Jeste li sigurni da želite ukoniti ovaj red?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div class="m-btn" @click="closeDelete"> Natrag</div>
                <div class="m-btn" @click="deleteItemConfirm"> Da</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field
            v-model="search"
            label="Pretraži tablicu"
            v-on:keydown.enter.prevent
            class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="table__icons" v-if="userRole === 'admin'">

          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
      <!--      <template v-slot:no-data>-->
      <!--        <v-btn-->
      <!--            color="primary"-->
      <!--            @click="initialize"-->
      <!--        >-->
      <!--          Osvježi-->
      <!--        </v-btn>-->
      <!--      </template>-->
    </v-data-table>
  </div>
</template>
<!-- eslint-disable -->

<script>


import formApi from "@/api/formApi";
import {mapActions} from "pinia";
import {useLoaderStore} from "@/stores/loader";
import {useFormStore} from "@/stores/form";
import {useAuthStore} from "@/stores/auth";

export default {
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    headersData: Array,
    setFormValues: Function,
    allData: Object,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [],
    editedIndex: -1,
    editedItem: {},
    tableItems: [],
    search: '',
    expanded: false,
    // selectModel: null,

  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '' : ''
    },

    userRole() {
      const authStore = useAuthStore();
      return authStore.userRole
    },

    hideIdColumn() {
      if (this.$route.matched[1]?.path === "/unos-ugovor/:id/5") {
        return 'hide-id-column-single'
      } else if (this.$route.matched[1]?.path === "/unos-ugovor/:id/6" ||
          this.$route.matched[1]?.path === "/unos-ugovor/:id/7" ||
          this.$route.matched[1]?.path === "/unos-zns/:id/2"
      ) {
        return 'hide-id-column'
      } else {
        return ''
      }
    },

    defaultItem() {
      const obj = {};
      for (const item of this.headersData) {
        if (item.fixed) {
          obj[item.value] = item.fixed;
        } else if (item.value !== 'actions') {
          obj[item.value] = '';
        }
      }
      return obj;
    },


  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    $route() {
      this.init();
      console.log('tableFrom init');
    },
    formData(newFormData, oldFormData) {
      this.$nextTick(function () {
        this.setNumberInputs()
        this.setRoleInputs()
      })
    },
  },

  created() {
    this.unwatchHeadersData = this.$watch('headersData', (newVal) => {
      if (newVal) {
        this.init()
        this.unwatchHeadersData()
      }
    })
    // this.unwatchTableData = this.$watch('tableData', (newVal) => {
    //   if (newVal) {
    //     this.init()
    //     this.unwatchTableData()
    //   }
    // })

  },

  mounted() {
    this.init()
    //on next tick
    this.$nextTick(() => {
      //remove all "*" character from th elements in table
      let table = document.querySelector('.v-data-table');
      let ths = table.querySelectorAll('th');
      ths.forEach(th => {
        th.innerHTML = th.innerHTML.replace('*', '');
      })
    })


  },


  methods: {

    ...mapActions(useLoaderStore, ['startLoading', 'stopLoading']),

    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },


    autoValue(e) {
      if (this.$route.matched[1]?.path === "/unos-ugovor/:id/7") {
        let esif = this.formData.tabs?.find(o => o.id == "4")?.form?.esif_fond;
        let stavka = this.editedItem.odabir_stavke;
        let stavkaTable = this.formData.tabs?.find(o => o.id == "6")?.form?.table?.find(o => o.naziv_stavke === stavka);
        let sredstva = stavkaTable?.tekuca_ili_kapitalna_sredstva;
        let iznos1 = stavkaTable?.ukupan_iznos_bespovratnih_sredstava?.replace(/\./g, '');
        let iznosSredstva = parseFloat(iznos1?.replace(/,/g, '.'))
        // let kfPercent = parseFloat(this.editedItem.kf?.replace(/,/g, '.'))
        // let dpPercent = parseFloat(this.editedItem.drzavni_proracun?.replace(/,/g, '.'))
        // let efrrPercent = parseFloat(this.editedItem.efrr?.replace(/,/g, '.'))
        let kfPercent = this.formData.tabs?.find(o => o.id == "4")?.form?.unos_postotka_ovisno_o_odabranom_esif_fondu;
        let dpPercent = this.formData.tabs?.find(o => o.id == "4")?.form?.drzavni_proracun;
        let efrrPercent = this.formData.tabs?.find(o => o.id == "4")?.form?.unos_postotka_ovisno_o_odabranom_esif_fondu;

        this.editedItem['kf'] = kfPercent
        this.editedItem['drzavni_proracun'] = dpPercent
        this.editedItem['efrr'] = efrrPercent

        if (esif === 'KF') {
          if (sredstva === 'Kapitalna') {
            if (kfPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((kfPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['562kf_k'] = formatedValueWithDot;
            }
            if (dpPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((dpPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['12dp_k'] = formatedValueWithDot;
            }
          }
          if (sredstva === 'Tekuća') {
            if (kfPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((kfPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['562kf_t'] = formatedValueWithDot;

            }
            if (dpPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((dpPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['12dp_t'] = formatedValueWithDot;
            }
          }
        }

        if (esif === 'EFRR') {
          if (sredstva === 'Kapitalna') {
            if (efrrPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((efrrPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['563efrr_k'] = formatedValueWithDot;
            }
            if (dpPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((dpPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['12dp_k'] = formatedValueWithDot;
            }
          }
          if (sredstva === 'Tekuća') {
            if (efrrPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((efrrPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['563efrr_t'] = formatedValueWithDot;
            }
            if (dpPercent && iznosSredstva) {
              let calculatedValue = this.roundToTwo((dpPercent / 100) * iznosSredstva);
              let formatedValue = calculatedValue.toString().replace('.', ',');
              let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
              this.editedItem['12dp_t'] = formatedValueWithDot;
            }
          }
        }
      }
      if (this.$route.matched[1]?.path === "/unos-zns/:id/2") {
        let izuzetniTroskovi1 = this.editedItem.izuzeti_troskovi?.replace(/\./g, '');
        let izuzetniTroskovi = parseFloat(izuzetniTroskovi1.replace(/,/g, '.'))
        let neprihvatljiviTroskovi1 = this.editedItem.neprihvatljivi_troskovi?.replace(/\./g, '');
        let neprihvatljiviTroskovi = parseFloat(neprihvatljiviTroskovi1?.replace(/,/g, '.'))
        let potrazivaniIznos1 = this.editedItem.potrazivani_iznos?.replace(/\./g, '');
        let potrazivaniIznos = parseFloat(potrazivaniIznos1?.replace(/,/g, '.'))

        let calculatedValue = this.roundToTwo(potrazivaniIznos - (izuzetniTroskovi + neprihvatljiviTroskovi));
        let formatedValue = calculatedValue.toString().replace('.', ',');
        let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        this.editedItem.odobreni_iznos = formatedValueWithDot;
      }
      if (this.$route.matched[1]?.path === "/unos-ugovor/:id/6") {

        let sufinanciranje = this.formData.tabs.find(o => o.id == "4").form.sufinanciranje;
        let sufinanciranjeFormated = parseFloat(sufinanciranje?.replace(/,/g, '.'))
        let iznos1 = this.editedItem.ukupan_iznos_stavke?.replace(/\./g, '');
        let iznosStavke = parseFloat(iznos1?.replace(/,/g, '.'))
        let iznosStavkePercent = (iznosStavke / 100)
        let calculatedValue = this.roundToTwo(sufinanciranjeFormated * iznosStavkePercent).toFixed(2);
        let formatedValue = calculatedValue.toString().replace('.', ',');
        //add dot to number for thousands
        let formatedValueWithDot = formatedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        if (sufinanciranje) {
          this.editedItem.ukupan_iznos_bespovratnih_sredstava = formatedValueWithDot;
        } else {
          this.editedItem.ukupan_iznos_bespovratnih_sredstava = this.editedItem.ukupan_iznos_stavke;
        }
        

      }
    },

    async setId(e) {
      console.log('e', e.target);
      console.log(e.target.attributes.name.value);
      if (this.$route.matched[1]?.path === "/unos-ugovor/:id/6") {
        if (e.target.attributes.name.value === "naziv_elementa") {
          this.editedItem.element_id = e.target.selectedOptions[0].id;
        }
      }
      if (this.$route.matched[1]?.path === "/unos-ugovor/:id/7") {
        this.editedItem.stavka_id = e.target.selectedOptions[0].id;
      }
      if (this.$route.matched[1]?.path === "/unos-zns/:id/2") {
        if (e.target.attributes.name.value === "naziv_stavke") {
          this.editedItem.stavka_id = e.target.selectedOptions[0].id;

          let idUgovora = this.allData?.ugovori?.find(ugovor => ugovor.broj_ugovora == this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora).id;
          console.log('idUgovora', idUgovora);
          console.log('broj ugovora', this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora);
          let currentUgovor;
          try {
            this.startLoading();

            let res;

            res = await formApi.get('ugovori?id=' + idUgovora);
            if (res.status === 200) {
              console.log('id res', res);
              this.stopLoading();
              currentUgovor = res.data;
              console.log('currentUgovor', currentUgovor);
            }

          } catch (error) {
            this.stopLoading();
            console.log('error', error);
            this.activateSnackbar(error.response.data.message, 'red');
          }
          currentUgovor.tabs[5].form.table.find(stavka => {
            console.log('stavka', stavka);
            console.log('this.editedItem', this.editedItem);
            if (stavka.naziv_stavke === this.editedItem.naziv_stavke) {
              this.editedItem.tekuca_ili_kapitalna_sredstva = stavka.tekuca_ili_kapitalna_sredstva;
            }
            if (stavka.id == this.editedItem.stavka_id) {
              this.editedItem.naziv_elementa = stavka.naziv_elementa;
            }
          })

        }

      }
    },

    checkInput(e) {
      // uncomment to return toggling between neprihvatljivi and izuzeti troskovi
      // if (this.$route.matched[1].path === "/unos-zns/:id/2") {
      //   if (e.srcElement.name === 'neprihvatljivi_troskovi') {
      //     this.editedItem.izuzeti_troskovi = "0";
      //   }
      //   if (e.srcElement.name === 'izuzeti_troskovi') {
      //     this.editedItem.neprihvatljivi_troskovi = "0";
      //   }
      // }
    },

    inputValue(item) {
      if (item.fixed) {
        return item.fixed;
      } else {
        return '';
      }
    },

    inputClass(item) {
      if (item.fixed || item.auto === '1') {
        return 'hide-input'
      } else {
        return ''
      }
    },

    // isNumber(evt) {
    //   const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','];
    //   const keyPressed = evt.key;
    //
    //   if (!keysAllowed.includes(keyPressed)) {
    //     evt.preventDefault()
    //   }
    // },

    setNumberInputs(type) {
      if (type === 'number') {
        // console.log('aaaaaa');
        let inputs = document.querySelectorAll("[numbervalidation='1']");
        // console.log(inputs);
        let self = this;
        if (inputs) {
          inputs.forEach(input => {
            input.addEventListener("blur", function () {
              var inputVal = input.value;
              console.log(inputVal);

              if (inputVal === "") {
                return;
              }

              var originalLen = inputVal.length;
              var caretPos = input.selectionStart;

              if (inputVal.indexOf(",") >= 0) {
                var decimalPos = inputVal.indexOf(",");
                var leftSide = inputVal.substring(0, decimalPos);
                var rightSide = inputVal.substring(decimalPos);
                leftSide = leftSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                rightSide = rightSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                rightSide = rightSide.substring(0, 2);
                input.value = leftSide + "," + rightSide;
                caretPos = caretPos - (originalLen - input.value.length);
                input.setSelectionRange(caretPos, caretPos);
              } else {
                input.value = inputVal.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                caretPos = caretPos - (originalLen - input.value.length);
                input.setSelectionRange(caretPos, caretPos);
                console.log('aaa',self.editedItem);
                console.log('bbb',input.name);
                console.log('ccc',input.value);

                self.editedItem[input.name] = input.value;

              }
            });
            input.addEventListener("keyup", function () {
              var inputVal = input.value;
              console.log(inputVal);

              if (inputVal === "") {
                return;
              }

              var originalLen = inputVal.length;
              var caretPos = input.selectionStart;

              if (inputVal.indexOf(",") >= 0) {
                var decimalPos = inputVal.indexOf(",");
                var leftSide = inputVal.substring(0, decimalPos);
                var rightSide = inputVal.substring(decimalPos);
                leftSide = leftSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                rightSide = rightSide.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                rightSide = rightSide.substring(0, 2);
                inputVal = leftSide + "," + rightSide;

              } else {
                inputVal = inputVal.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                // inputVal = inputVal;
              }

              input.value = inputVal;

              var updatedLen = inputVal.length;
              caretPos = updatedLen - originalLen + caretPos;
              input.setSelectionRange(caretPos, caretPos);
            });
          })
        }
      }
    },


    init() {
      console.log('tableForm init');
      // console.log('headers', this.headers);
      this.headers = this.headersData;

      let stepData = {...this.formData?.tabs?.find(o => o.id == this.$route.name)};
      console.log('this.formData', this.formData);
      console.log('stepData', stepData);

      if (stepData?.form?.table) {
        this.tableItems = [...stepData.form.table]
        console.log('this.tableItems', this.tableItems);
      }

      // this.setTableData(this.tableItems);
    },

    editItem(item) {
      this.editedIndex = this.tableItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.tableItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.tableItems.splice(this.editedIndex, 1)
      this.$parent.$emit('setFormValues', {table: this.tableItems});
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = this.default;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1;
        this.$formulate.resetValidation('myForm2')
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$formulate.resetValidation('myForm2')

      })
    },

    save(data) {
      // let result = !Object.values(this.editedItem).some(o => o === "");
      console.log('this.editedItem', this.editedItem);
      console.log('this.data', data);

      if (this.editedIndex > -1) {
        Object.assign(this.tableItems[this.editedIndex], this.editedItem)
        // this.setTableData(this.tableItems);
        this.$parent.$emit('setFormValues', {table: this.tableItems});
        this.close()

      } else {
        console.log('this.tableItems', this.tableItems);
        this.tableItems.push(this.editedItem)
        this.$parent.$emit('setFormValues', {table: this.tableItems});
        this.close()
        //remove all "*" character from th elements in table
        let table = document.querySelector('.v-data-table');
        let ths = table.querySelectorAll('th');
        ths.forEach(th => {
          th.innerHTML = th.innerHTML.replace('*', '');
        })

      }
    },
  },
}
</script>
<style lang="scss">

.form__inner--table {
  overflow-x: hidden;
}

.hide-input {
  height: 0;
  max-width: 0 !important;
  min-width: 0 !important;
  width: 0;
  opacity: 0;
  position: absolute !important;
  left: 999999999999999px;
}


.v-dialog {
  .formulate-form {
    flex-wrap: wrap;
  }
}

.table-expanded {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 100vw;
  z-index: 1;
  overflow-y: auto;
}

.v-toolbar__content {
  .m-btn {
    margin: 0;
    margin-right: 10px;
  }
}

.table-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.sortable {
  i {
    top: -4px;
  }
}

.hide-id-column-single {
  .v-data-table__wrapper {
    th, td {
      &:last-of-type {
        opacity: 0;
        padding: 0;
      }
    }
  }

  .v-data-table__empty-wrapper {
    th, td {
      &:last-of-type {
        opacity: 1;
        padding: 0 16px;
      }
    }
  }

}

.hide-id-column {
  .v-data-table__wrapper {
    th, td {
      &:last-of-type {
        opacity: 0;
        padding: 0;
      }

      &:nth-last-of-type(2) {
        opacity: 0;
        padding: 0;
      }
    }
  }

  .v-data-table__empty-wrapper {
    th, td {
      &:last-of-type {
        opacity: 1;
        padding: 0 16px;
      }

      &:nth-last-of-type(2n) {
        opacity: 1;
        padding: 0 16px;
      }
    }
  }

}

.back-icon {
  margin-left: auto;
  cursor: pointer;
  transform: scale(1.3);
}

@media (max-width: 599px) {

  .v-data-footer {
    padding: 0;
  }

  .v-data-table__wrapper {

    .v-data-table-header-mobile__wrapper {
      padding: 10px 0;
    }

    .v-data-table__mobile-row__header {
      font-size: 13px;
    }

    .v-data-table__mobile-row__cell {
      font-size: 10px;
    }


    .v-input__slot {
      &:before {
        background: var(--white) !important;
      }

      &:after {
        background-color: var(--white) !important;
        border-color: var(--white) !important;

      }
    }

    .v-select__slot {
      * {
        color: var(--white) !important;
      }

      .v-chip {
        background: var(--white) !important;
        height: 20px;
      }

      .v-chip__content {
        color: var(--blue) !important;
        font-size: 10px;
      }

    }
  }
}

</style>

