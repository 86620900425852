<template>
  <TableForm key="u9" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData"/>
</template>


<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
  },

  computed: {

    brojUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora;
    },


    headersData() {
      return [
        {text: 'Broj ugovora', value: 'broj_ugovora', fixed: this.brojUgovora},
        {
          text: 'Iznos korekcije(EUR)*',
          value: 'iznos_korekcije',
          validation: 'required',
          type: 'number',
          align: 'right',
          placeholder: 'Unesite iznos'
        },
        {
          text: 'JN manja od granta(EUR)*',
          value: 'jn_manja_od_granta',
          validation: 'required',
          type: 'number',
          placeholder: 'Unesite iznos',
          align: 'right',
        },
        {
          text: 'Trenutni status',
          value: 'trenutni_status',
          type: 'select',
          options: [{value: 'U provedbi', label: 'U provedbi'}, {value: 'Izvršen', label: 'Izvršen'}, {value: 'Raskinut', label: 'Raskinut'}]
        },
        {text: 'Napomena', value: 'Napomena', placeholder: 'Unesite napomenu', type: 'textarea'},
        {text: '', value: 'actions', sortable: false},
      ]
    },
  },
}
</script>

