import {defineStore} from 'pinia'

export const useLoaderStore = defineStore('loader', {

    // could also be defined as
    state: () => ({
      isLoading: false,
    }),
    actions: {
        startLoading() {
           this.isLoading = true;
        },
        stopLoading() {
            this.isLoading = false;
        },
    },

})