import {defineStore} from 'pinia'
import formApi from "@/api/formApi";
import {pinia} from "@/main";
import {useLoaderStore} from "@/stores/loader";

export const useAuthStore = defineStore('auth', {

    state: () => ({
        isAuthenticated: false,
        userRole: null,
    }),
    actions: {
        async getAuthStatus() {
            const loaderStore = useLoaderStore(pinia);
            // this.isAuthenticated = true;

            try {
                loaderStore.startLoading();
                const res = await formApi.post('auth');
                // const data = res.data;
                if (res.status === 200) {
                    console.log('getAuthStatus',res);

                    if(res.data['is_authorized']){
                        this.isAuthenticated = true;
                    }
                    this.userRole = res.data['user_role'];
                    // this.userRole = 'user'
                    loaderStore.stopLoading();
                }
            } catch (e) {
                loaderStore.stopLoading();
            }
        },
        async logout() {
            console.log('logout');
            const loaderStore = useLoaderStore(pinia);
            try {
                loaderStore.startLoading();
                const res = await formApi.post('logout');
                // const data = res.data;
                if (res.status === 200) {
                    console.log(res);
                    // this.isAuthenticated = false;
                    // this.userRole = null;
                    loaderStore.stopLoading();
                    location.reload();
                }
            } catch (e) {
                loaderStore.stopLoading();
            }
        }
    },
    getters: {
        // formData: (state) => state.formData,
    },
})