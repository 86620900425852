<template>
  <TableForm key="z4" :headersData="headersData" :formData="formData" :tableData="tableData"
             :setTableData="setTableData"/>
</template>

<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object

  },
  data: () => ({}),
  computed: {
    brojUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora;
    },
    brojZnsa() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_znsa;
    },

    headersData() {


      return [
        {text: 'Broj ugovora', value: 'broj_ugovora', fixed: this.brojUgovora},
        {text: 'Broj ZNS-a', value: 'broj_znsa', fixed: this.brojZnsa},

        {
          text: 'Iznos korekcije(EUR)*',
          value: 'iznos_korekcije',
          validation: 'required',
          type: 'number',
          align: 'right',
          placeholder: 'Unesite iznos'
        },
        {
          text: 'Napomena',
          value: 'napomena',
          type: 'textarea',
          placeholder: 'Unesite napomenu'
        },
        {
          text: 'Datum korekcije*',
          value: 'datum_korekcije',
          placeholder: 'dd.mm.gggg',
          validation: 'required',
          type: 'date'
        },

        {text: '', value: 'actions', sortable: false},
      ]
    },
  },

}

</script>


