<template>
  <div class="form__inner">
    <FormulateInput role="presentation" autocomplete="off"
        name="broj_ugovora"
        type="select"
        label="Broj ugovora*"
        placeholder="Odaberi"
        validation="required"
        :validation-messages="{required: 'Ovo polje je obavezno'}"
        :options="brojeviUgovora"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="datum_podnosenja_zns-a"
        type="date"
        label="Datum podnošenja ZNS-a"
        placeholder="DD.MM.GGGG."
        :validation-messages="{after: 'Format nije ispravan'}"
        :validation="'optional|after:1900-01-01'"

    />
    <FormulateInput role="presentation" autocomplete="off"
        name="broj_znsa"
        type="text"
        label="Broj ZNS-a*"
        placeholder="Unesite broj ZNS-a"
        validation="required"
        :validation-messages="{required: 'Ovo polje je obavezno'}"

    />

    <FormulateInput role="presentation" autocomplete="off"
        name="datum_zaprimanja_odobrenja_znsa"
        type="date"
        label="Datum zaprimanja odobrenja ZNS-a"
        placeholder="DD.MM.GGGG."
        :validation-messages="{after: 'Format nije ispravan'}"
        :validation="'optional|after:1900-01-01'"
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="vrsta_zns-a"
        type="select"
        label="Vrsta ZNS-a*"
        placeholder="Odaberi"
        validation="required"
        :validation-messages="{required: 'Ovo polje je obavezno'}"
        :options="[
                { value: '', label: 'Odaberite' },
    { value: 'Među zahtjev', label: 'Među zahtjev' },
    { value: 'Završni zahtjev', label: 'Završni zahtjev' },
  ]"
    />


    <FormulateInput role="presentation" autocomplete="off"
        name="rok_za_odobrenje"
        type="date"
        label="Rok za odobrenje"
        placeholder="DD.MM.GGGG."
        v-model="rok"
        disabled
    />
    <FormulateInput role="presentation" autocomplete="off"
        name="datum_odobrenja_odbijanja_znsa"
        type="date"
        label="Datum odobrenja/odbijanja ZNS-a"
        placeholder="DD.MM.GGGG."
        :validation-messages="{after: 'Format nije ispravan'}"
        :validation="'optional|after:1900-01-01'"

    />
    <FormulateInput role="presentation" autocomplete="off"
        name="komentar"
        type="textarea"
        label="Komentar"
        placeholder="Unesi komentar"
    />

  </div>
</template>

<script>

import {isWeekend, isHoliday} from '@/config/dates'
import {mapState} from "pinia";
import {useFormStore} from "@/stores/form";

export default {
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object,
    formValues: Object,
    setFormValues: Function,
  },
  data: () => ({}),
  computed: {
    ...mapState(useFormStore, ['brojeviUgovora']),

    rok() {
      console.log('this.formValues', this.formValues);
      if (this.formValues["vrsta_zns-a"] && this.formValues['datum_podnosenja_zns-a']) {
        let date = new Date(this.formValues['datum_podnosenja_zns-a']);
        if (this.formValues["vrsta_zns-a"] === 'Među zahtjev') {
          date.setDate(date.getDate() + 30);
        } else if (this.formValues["vrsta_zns-a"] === 'Završni zahtjev') {
          date.setDate(date.getDate() + 60);
        }
        while (isWeekend(date) || isHoliday(date)) {
          date.setDate(date.getDate() + 1);
        }
        return date.toISOString().split('T')[0];
      }
      else if (this.formValues.rok_za_odobrenje) {
        return this.formValues.rok_za_odobrenje;
      }
      else {
        return null;
      }
    }
  },
  methods: {}
}

</script>

