<template>
  <TableForm key="z3" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData"/>
</template>

<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
    allData: Object

  },
  data: () => ({}),
  computed: {
    years() {
      let years = [];
      let currentYear = new Date().getFullYear();
      // allow years in the future as well
      for (let i = currentYear+9; i >= 1900; i--) {
        years.push(i);
      }
      return years;
    },

    headersData() {


      return [
        {
          text: 'Broj zahtjeva*',
          value: 'broj_zahtjeva',
          type: 'number',
          validation: 'required',
          placeholder: 'Unesite broj zahtjeva'
        },
        {
          text: 'Godina',
          value: 'godina',
          type: 'select',
          options: this.years
        },
        {
          text: 'Potraživani iznos prihvatljivih troškova(EUR)*', validation: 'required',
          value: 'potrazivani_iznos_prihvatljivih_troskova_hrk',
          align: 'right',
          type: 'number',
          placeholder: 'Unesite iznos'
        },

        {
          text: 'Mjesec',
          value: 'mjesec',
          type: 'select',
          options: [
            {label: 'Siječanj', value: 'Siječanj'},
            {label: 'Veljača', value: 'Veljača'},
            {label: 'Ožujak', value: 'Ožujak'},
            {label: 'Travanj', value: 'Travanj'},
            {label: 'Svibanj', value: 'Svibanj'},
            {label: 'Lipanj', value: 'Lipanj'},
            {label: 'Srpanj', value: 'Srpanj'},
            {label: 'Kolovoz', value: 'Kolovoz'},
            {label: 'Rujan', value: 'Rujan'},
            {label: 'Listopad', value: 'Listopad'},
            {label: 'Studeni', value: 'Studeni'},
            {label: 'Prosinac', value: 'Prosinac'},
          ]
        },
        {
          text: 'Vrsta ZNS-a*', value: 'vrsta_znsa', type: 'select', validation: 'required', options: [
            {label: 'Među zahtjev', value: 'Među zahtjev'},
            {label: 'Završni zahtjev', value: 'Završni zahtjev'},
          ]
        },

        {
          text: 'Komentar',
          value: 'Komentar',
          type: 'textarea',
          placeholder: 'Unesite komentar'
        },

        {text: '', value: 'actions', sortable: false},
      ]
    },
  },

}

</script>


