<template>
  <div class="unos__btns">
    <button type="submit" v-on:keydown.enter.prevent @click="setBtnType('submit')" class="m-btn">
      <span>Spremi</span>
    </button>
    <div @click="goBack" v-on:keydown.enter.prevent class="m-btn"
         :class="Number(this.$route.name) < 2 && 'm-btn--disabled'">
      <span>Natrag</span>
    </div>
    <button type="submit" @click="setBtnType('next')" class="m-btn">
      <span>Dalje</span>
    </button>

    <button v-if="$route.matched[1].path === '/unos-ugovor/:id/7'" type="submit" v-on:keydown.enter.prevent
            @click="setBtnType('end')" class="m-btn">
      <span>Završi</span>
    </button>

  </div>

</template>
<script>

export default {
  components: {},
  props: {
    setBtnType: Function
  },
  methods: {
    goBack() {
      console.log(Number(this.$route.name));
      if (Number(this.$route.name) > 1) {
        let path = this.$route.fullPath.substr(0, this.$route.fullPath.lastIndexOf("/")) + '/' + (Number(this.$route.name) - 1);
        console.log('back path', path);
        this.$router.push(path);
      }
    }
  },

  mounted() {
    console.log(this.$route);
  }
}
</script>

<style lang="scss">
.unos__btns {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding-left: 30px;
  max-width: 192px;
  margin-left: auto;
  @media only screen and (max-width: 1023px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  .m-btn {
    width: 100%;
  }
}

.m-btn--disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
