<template>
  <TableForm key="u8" :headersData="headersData" :formData="formData" :tableData="tableData" :setTableData="setTableData"/>
</template>


<script>
import TableForm from '@/components/TableForm';

export default {
  components: {TableForm},
  props: {
    formData: Object,
    tableData: Array,
    setTableData: Function,
  },

  computed: {

    brojUgovora() {
      return this.formData.tabs?.find(o => o.id == 1)?.form?.broj_ugovora;
    },


    headersData() {
      return [
        {text: 'Broj ugovora', value: 'broj_ugovora', fixed: this.brojUgovora},
        {
          text: 'Vrsta izmjene*',
          value: 'vrsta_izmjene',
          validation: 'required',
          type: 'select',
          options: [{value: 'Manja izmjena', label: 'Manja izmjena'}, {value: 'Veća izmjena', label: 'Veća izmjena'}]
        },
        {text: 'Opis izmjene*', value: 'opis_izmjene', placeholder: 'Unesite opis izmjene', validation: 'required'},
        {text: 'Datum izmjene*', value: 'datum_izmjene', placeholder: 'dd.mm.gggg', validation: 'required', type: 'date'},
        {text: 'Komentar', value: 'komentar', placeholder: 'Unesite komentar', type: 'textarea'},
        {text: '', value: 'actions', sortable: false},
      ]
    },
  },
}
</script>

